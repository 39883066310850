import React from 'react';

export const AnnoteInformation = (
  <>
    <h2>What is an annotated bibliography?</h2>
    <p>
      In an annotated bibliography, each citation is followed by a brief
      (usually about 150 words) annotation to inform the reader of the
      relevance, accuracy, and quality of the sources cited.
    </p>
    <p>
      If you would like to add an annotation to your source, paste it in the
      field below.
    </p>
  </>
);

export const BookModalInformation = {
  contributors: (
    <>
      <h2>
        Cite the full name(s) of the book author(s) and other contributors.
      </h2>
      <p>
        If your source has more than one author, you can add them below as well
        as specify translators or editors, if applicable. If the book has been
        authored by an organization or a company, select the
        &lsquo;Organization&rsquo; option and paste the company&apos;s name.
      </p>
      <strong> MLA format:</strong>
      <p>
        Manhattan Prep. GMAT Advanced Quant: 250+ Practice Problems & Online
        Resources. Simon and Schuster, 2020.
      </p>
      <strong> APA format:</strong>
      <p>
        Manhattan Prep. (2020). GMAT advanced quant: 250+ practice problems &
        online resources. Simon and Schuster.
      </p>
    </>
  ),
  issued: (
    <>
      <h2> Cite the date when the book was published.</h2>
      <p>
        The publication year helps your readers understand the historical
        context behind your sources as well as how relevant and up-to-date your
        references are.
      </p>
      <p>
        Keep in mind that each publisher or new edition of a book has a
        different publication date. For instance, Pride and Prejudice was
        originally published in 1813 and republished in 1853, 1918, 2013, and so
        on.
      </p>
      <p>
        Normally, it&apos;s enough to enter the date when the book was
        originally published, but if you are looking to create the most accurate
        citation, we suggest that you enter the publication year that
        corresponds to the specific edition or publisher of your source.
      </p>
    </>
  ),
  'original-date': (
    <>
      <h2>Cite the date when the book was originally published.</h2>
      <p>
        {' '}
        If you are citing a republished book, make sure to include the original
        publication date to help readers place your sources and references in
        the historical context.{' '}
      </p>
    </>
  ),
  edition: (
    <>
      <h2> Cite the book&apos;s edition. </h2>
      <p>
        Leave blank if you&apos;re citing the first edition. If you are
        referencing a different version of the book than the original, remember
        to include the book&apos;s particular edition to avoid any
        misunderstandings. Both MLA and APA formats use the abbreviation
        &lsquo;ed.&rsquo; to indicate which version of the source has been
        referenced, such as: - Updated ed. - Rev. ed. (revised edition). - 2nd
        ed., 3rd ed., 4th ed., and so on.
      </p>
    </>
  ),
  volume: (
    <>
      <h2>Cite the book&apos;s volume number. </h2>
      <p>
        Omit the volume number when the source you consulted has a different
        title than other volumes or if you’re citing the set of volumes as a
        whole. To cite a particular book out of a multi-volume set, input the
        volume number and the abbreviation &lsquo;vol.&rsquo; will automatically
        be added after completing the citation.
      </p>
      <strong>APA format:</strong>
      <p>Marx, K. (1885). Capital (Vol. 2). Verlag von Otto Meisner. </p>
      <strong> MLA format:</strong>
      <p>Marx, Karl. Capital . Vol. 2. Verlag von Otto Meisner, 1885. Print.</p>
    </>
  ),
  publisher: (
    <>
      <strong>Cite the book&apos;s publisher.</strong>
      <p>
        Some of the largest book publishers worldwide are: - Penguin/Random
        House. - Hachette Book Group. - Harper Collins. - Simon and Schuster. -
        Macmillan. When citing more than one publisher, place a semicolon
        between each one. Make sure to include the place of publication for the
        books that don&apos;t have a known publisher. Every publishing company
        has a different system for page numbering, text formatting and book
        sizing. As a result, it is important to cite the correct publisher for
        your source to avoid a mismatch in your page references.
      </p>
    </>
  ),
  'publisher-place': (
    <>
      <h2>Cite the city where the publishing company is located.</h2>
      <p>
        When more than one city is listed for the same publisher, use only the
        first city. Note that it&apos;s quite common for books to be published
        in one version in North America, and slightly differently elsewhere. In
        a case where the page numbering is different in the British and the
        American versions of the same book, your citations will become
        incompatible for the readers consulting a wrong version of the source.
      </p>
      <strong> MLA format:</strong>
      <p>
        Adams, Douglas. The Hitchhiker’s Guide to the Galaxy. London: Pan
        MacMillan, 2017. Print.
      </p>
      <strong>APA format:</strong>
      <p>
        Adams, D. (2017). The Hitchhiker’s Guide to the Galaxy. London: Pan
        MacMillan. (Original work published 1979).
      </p>
    </>
  ),
  page: (
    <>
      <h2>Cite the page range. </h2>
      <p>
        Do not include a page range if you are citing a book as a whole. (But
        don&apos;t forget to indicate the specific pages you&apos;re citing in
        your in-text citations!) A page range is only included in a reference to
        a source that is part of a larger paginated work, such as a chapter in
        an edited book or a work in an anthology.
      </p>
      <strong>MLA format:</strong>{' '}
      <p>pp. 125-653, pp. 261-89 (note that the repeated digit is omitted). </p>
      <strong>APA format:</strong> <p>261-289, 125-653.</p>
    </>
  ),
  ISBN: (
    <>
      <h2>Should I cite a book&apos;s ISBN? </h2>
      <p>
        Like an ID to a human, an ISBN is a unique identification number
        assigned to each separate edition and version of a book publication
        (both online and in print). The most common citation formats, such as
        MLA, APA or Chicago, *do not require an ISBN number,* since all the
        information about a book&apos;s edition, volume and publisher has
        already been incorporated in the body of a reference.
      </p>
    </>
  ),
};

export const JournalArticleModalInformation = {
  contributors: (
    <>
      <h2>
        Cite the full name(s) of the article author(s) and other contributors.
      </h2>

      <p>
        If your source has more than one author, you can add them below as well
        as specify the article&apos;s editors, if applicable.
      </p>

      <p>
        If the journal article has been written by an organization rather than
        individual authors, select the &lsquo;Organization&rsquo; option and
        paste the company&apos;s name.
      </p>

      <p>
        <strong>MLA format:</strong> United Nations. “Secretaries-General of the
        United Nations.” UN Chronicle, vol. 52, no. 2, United Nations
        Publications, Dec. 2015, pp. 36–37, doi:10.18356/71469c4c-en.
      </p>

      <p>
        <strong>APA format:</strong> United Nations. (2015). Secretaries-General
        of the United Nations. UN Chronicle, 52(2), 36–37.
        [https://doi.org/10.18356/71469c4c-en](https://doi.org/10.18356/71469c4c-en)
      </p>
    </>
  ),
  issued: (
    <>
      <h2>Cite the year when the article was published.</h2>
      <p>
        The publication date helps your readers understand the historical
        context behind your sources and how relevant your references are.
      </p>
    </>
  ),
  title: (
    <>
      <h2>Cite the full title and subtitle (if applicable) of the article.</h2>
      <p>Remember to separate the title and subtitle with a colon.</p>
      <p>
        <strong>MLA format:</strong> Make sure that all the principal words in
        the title (nouns, pronouns, verbs, adjectives, adverbs, and some
        conjunctions) are capitalized.
      </p>

      <p>
        <em>
          Sherlock, Mary, and Danielle L. Wagstaff. “Exploring the Relationship
          between Frequency of Instagram Use, Exposure to Idealized Images, and
          Psychological Well-Being in Women.” Psychology of Popular Media
          Culture, vol. 8, no. 4, American Psychological Association (APA), Oct.
          2019, pp. 482–90, doi:10.1037/ppm0000182.
        </em>
      </p>

      <p>
        <strong>APA format:</strong> Capitalize only the first word of the title
        and subtitle, the first word after a colon or a dash, and any other
        proper nouns.
      </p>

      <p>
        <em>
          Sherlock, M., & Wagstaff, D. L. (2019). Exploring the relationship
          between frequency of Instagram use, exposure to idealized images, and
          psychological well-being in women. Psychology of Popular Media
          Culture, 8(4), 482–490. https://doi.org/10.1037/ppm0000182
        </em>
      </p>
    </>
  ),
  'container-title': (
    <>
      <h2>Cite the name of the journal where the article was published.</h2>

      <p>
        Every scholarly journal is assigned a specific impact factor that
        determines the relative importance of a journal within its field. The
        higher the journal&apos;s impact factor is, the more important and
        trustworthy your reference will be. Using unreliable sources might have
        a negative effect on your grade, so we recommend that you always check
        the impact factor of the journals that you&apos;re citing.
      </p>

      <p>
        Beware of the &ldquo;predatory publications&rdquo; — the journals that
        publish academic articles for profit and are therefore regarded as
        fraudulent publications. Do not cite these publications in your work!
      </p>

      <p>Some predatory publications include:</p>

      <ul>
        <li>Global Science Research Journals</li>
        <li>Institute of Advanced Scientific Research</li>
        <li>International Center for Business Research</li>
        <li>Journal of Comprehensive Research</li>
        <li>World Science and Research Publishing</li>
      </ul>
      <p>
        Visit Beall&apos;s List (https://beallslist.net/) to see the full list
        of predatory scholarly publishers.
      </p>
    </>
  ),
  volume: (
    <>
      <h2>Cite the journal&apos;s volume number.</h2>

      <p>
        The journal&apos;s volume number refers to the number of years the
        publication has been printed.{' '}
      </p>

      <p>
        To cite a journals volume number, input just the numerical digit and the
        citation will be automatically formatted according to format guidelines.
      </p>

      <p>
        <strong>MLA format:</strong> vol. 44, no. 2
      </p>

      <p>
        <strong>APA format:</strong> 44(2)
      </p>
    </>
  ),
  issue: (
    <>
      <h2>Cite the journal&apos;s issue number.</h2>

      <p>
        The journal&apos;s issue number refers to how many times the journal has
        been published during that specific year.
      </p>

      <p>
        To cite a journal&apos;s issue number, input just the numerical digit
        and the citation will be automatically formatted according to format
        guidelines.
      </p>

      <p>
        Depending on the format you have chosen, the &apos;vol.&apos; and
        &apos;no.&apos; abbreviations may not be necessary:
      </p>

      <p>
        <strong>MLA format:</strong> vol. 44, no. 2
      </p>

      <p>
        <strong>APA format:</strong> 44(2)
      </p>
    </>
  ),
  page: (
    <>
      <h2>Cite the page range.</h2>

      <p>
        To include the page range, input just the numerical digits and the
        citation will be automatically formatted according to format guidelines.
      </p>

      <p>
        <strong>MLA format:</strong> pp. 125-653, pp. 261-89 (note that the
        repeated digit is omitted).
      </p>

      <p>
        <strong>APA format:</strong> 261-289, 125-653.
      </p>
    </>
  ),
  archive: (
    <>
      <h2>Cite the database where the journal article can be accessed.</h2>

      <p>
        Every minute, two scholarly articles are published. Journal databases
        help researchers navigate the ever-growing world of academic publishing
        and serve as digital libraries that contain the information about a
        variety of journals, publications, and primary sources.
      </p>
    </>
  ),
  accessed: (
    <>
      <p>
        <strong>MLA format:</strong> While it is not required to do so, MLA
        guidelines encourage students to indicate the date when they consulted
        the source.
      </p>

      <p>
        <strong>APA format:</strong> No information needed.
      </p>
    </>
  ),
  DOI: (
    <>
      <h2>Cite the article&apos;s DOI.</h2>
      <p>
        Like an ID to a person, a DOI is a unique identification number assigned
        to journal articles and ebooks. A DOI can be easily found on the
        database page of a journal article.
      </p>
      <p>
        When no DOI is available, include the source URL or omit it entirely if
        you accessed the article through a database or a print version.
      </p>
    </>
  ),
};

export const WebsiteModalInformation = {
  contributors: (
    <>
      <h2>
        Cite the full name(s) of the web page author(s) and other contributors.
      </h2>
      <p>
        If your source has more than one author, you can add them below as well
        as specify the page&apos;s editors, if applicable.
      </p>
      <p>
        If no author is indicated, select the &apos;Organization&apos; option
        and paste the title of the website.
      </p>
      <p>
        <strong>MLA format:</strong> Niche. “2021 Best Test Optional Colleges in
        America.” Niche, 2021,
        https://www.niche.com/colleges/search/best-test-optional-colleges/
      </p>
      <p>
        <strong>APA format:</strong> Niche. (2021). 2021 Best Test Optional
        Colleges in America. Retrieved March 5, 2021, from Niche website:
        https://www.niche.com/colleges/search/best-test-optional-colleges/
      </p>
    </>
  ),
  issued: (
    <>
      <h2>Cite the year when the web page was created.</h2>
      <p>
        If no year is indicated, leave the field blank and the abbreviation n.d.
        will be added automatically.
      </p>
    </>
  ),
  title: (
    <>
      <h2>Cite the full title and subtitle (if applicable) of the web page.</h2>
      <p>
        The web page title is usually clearly visible and located at the top of
        a page or an article that you&apos;re citing.
      </p>
      <p>Remember to separate the title and subtitle with a colon.</p>
      <p>
        <strong>MLA format:</strong> Make sure that all the principal words in
        the title (nouns, pronouns, verbs, adjectives, adverbs, and some
        conjunctions) are capitalized.
      </p>
      <p>
        Lowrey, Annie. “Stockton’s Basic-Income Experiment Pays Off.” The
        Atlantic, 3 Mar. 2021,
        https://www.theatlantic.com/ideas/archive/2021/03/stocktons-basic-income-experiment-pays-off/618174/
      </p>
      <p>
        <strong>APA format:</strong> Capitalize only the first word of the title
        and subtitle, the first word after a colon or a dash, and any other
        proper nouns.{' '}
      </p>
      <p>
        Lowrey, A. (2021, March 3). Stockton’s basic-income experiment pays off.
        The Atlantic. Retrieved from
        https://www.theatlantic.com/ideas/archive/2021/03/stocktons-basic-income-experiment-pays-off/618174/
      </p>
    </>
  ),
  'container-title': (
    <>
      <h2>Cite the name of the website that you accessed.</h2>
      <p>
        The name of the website can be found in the page header or the address
        bar.
      </p>
      <p>Examples: Netflix, Coursera, The Atlantic, Buzzfeed.</p>
    </>
  ),
  publisher: (
    <>
      <h2>Cite the website publisher.</h2>
      <p>
        A website publisher is an organization or an institution that sponsors
        and owns the website. This information can usually be found at the
        bottom a web page or the copyright notice.
      </p>
      <p>
        Leave the field blank if the name of the publisher is the same as the
        website name.
      </p>
      <p>Example: © 2021 World Health Organization</p>
    </>
  ),
  URL: (
    <>
      <h2>Cite the web page URL.</h2>
      <p>
        The web page URL can be found in your browser&apos;s address bar. If
        citing in the MLA format, remember to delete the protocol identifiers,
        such as https:// and http://.
      </p>
      <p>
        Example:
        www.nytimes.com/interactive/2021/03/03/science/vaccine-efficacy-coronavirus.html
      </p>
    </>
  ),
  accessed: (
    <>
      <p>
        <strong>MLA format:</strong> While it is not required to do so, the MLA
        format guidelines encourage students to indicate the date when they
        consulted the source.
      </p>
      <p>
        <strong>APA format:</strong> No information needed.
      </p>
    </>
  ),
};
