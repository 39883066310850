import { Citation } from 'redux/modules/citation-module';
import { CitationStyle } from 'types/citation-style';
import { runningInGdocs } from './gdocs';
import { getRequestData } from './gdocs/document-store';

type ChangedList = {
  citations: Citation[];
  id: string;
  style: CitationStyle;
  gdocId?: string;
};

const KEY_NAME = 'bibcitation-list';

export const publishListChange = ({ id, citations, style }: ChangedList) => {
  localStorage?.setItem(
    KEY_NAME,
    JSON.stringify({
      id,
      gdocId: runningInGdocs() ? getRequestData().gdocId : undefined,
      citations,
      style,
    }),
  );
};

export const onListChange = (callback: (list: ChangedList) => void) => {
  window.addEventListener('storage', (event) => {
    if (event.key !== KEY_NAME || !event.newValue) {
      return;
    }
    const updatedList = JSON.parse(event.newValue) as ChangedList;
    callback(updatedList);
  });
};
