// @ts-expect-error citeproc-js has no types
import CSL from 'citeproc-js';
import { CitationData, Name } from 'types/fields';
import locale from 'lib/en-US-locale';
import CitationTypes from 'types/citation-types';
import outputFormats from 'types/output-formats';
import citationForms from 'types/citation-forms';

// TODO consider creating a singleton for this module
// https://citeproc-js.readthedocs.io/en/latest/running.html?highlight=previewcitationcluster#special-citation-forms
export type CiteProcModifiers = {
  locator?: string;
  label?: 'page';
  'suppress-author'?: boolean;
  'author-only'?: boolean;
  // there's more but citeproc documentation bad
};

// return type for citeproc.rebuildProccessorState: https://github.com/Juris-M/citeproc-js-docs/blob/master/original.txt
export type CiteProcRebuildProcessorOutput = [
  string, // citation id
  number, // note index
  string, // in-text citation
][];

export enum CitationLocation {
  Intext,
  Footnote,
}

export type CitationDataArray = {
  citationType: CitationTypes;
  data: CitationData;
}[];
export type ProcessedCitationData = {
  bibliography: any;
};

let citationDataOutside: CitationDataArray = [];
let currentStyle: { name: string; xml: string };

const retrieveItemOutside = (id: string): Record<string, any> => {
  const foundCitation = citationDataOutside.find(
    (citation) => citation.data.id === id,
  );
  if (foundCitation) {
    return foundCitation.data;
  }
  return {};
};

const authorDoesNotExist = (author: Name): boolean => {
  return !author.given && !author.family && !author.literal;
};

const generateCiteproc = (citationXML: string): Record<string, any> => {
  const citeproc = new CSL.Engine(
    {
      retrieveItem: (id: string) => {
        return retrieveItemOutside(id);
      },
      retrieveLocale: () => {
        return locale;
      },
    },
    citationXML,
  );
  return citeproc;
};

let citeproc: Record<string, any>;

export const isCiteprocInitialized = (): boolean => {
  return typeof citeproc !== 'undefined';
};

// returns citation location (in-text or footnote) to allow caller to update the store
export const setCSLCitationStyle = (citationStyle: {
  name: string;
  xml: string;
}): void => {
  currentStyle = citationStyle;
  citeproc = generateCiteproc(currentStyle.xml);
};

// citeproc needs to be setup with specifically formatted citations before it can create bibs
export const setupCiteProc = (
  citationData: CitationDataArray,
  outputFormat: outputFormats = outputFormats.html,
): string[] => {
  citationDataOutside = processCitationDataForCiteProc(citationData);
  const citationIDs = citationData.map((citation) => citation.data.id);
  citeproc.deleteAll();
  citeproc.setOutputFormat(outputFormat);
  citeproc.updateItems(citationIDs);
  return citationIDs;
};

export const clearCiteProc = (): void => {
  // @ts-expect-error TODO remove ts ignore if you make this module a singleton
  citeproc = undefined;
};

const getCitations = (
  citationData: CitationDataArray,
  outputFormat: outputFormats = outputFormats.html,
): ProcessedCitationData => {
  const citationIDs = setupCiteProc(citationData, outputFormat);
  let bib = citeproc.makeBibliography();

  // bib doesn't exist for some styles e.g. 'Bluebook' styles
  if (!bib) {
    bib = [{ entry_ids: citationIDs.map((id) => [id]) }];
  }

  return { bibliography: bib };
};

// Deep clones citation data to avoid mutating state redux state object, and
// modifies to fit citeproc api better.
const processCitationDataForCiteProc = (
  citationDataArray: CitationDataArray,
): CitationDataArray => {
  const newCitationDataArray = citationDataArray.map((citation) => {
    const citationData = citation.data;
    const citationDataAuthorsProcessed = citationData.author
      ? { ...citationData, author: processAuthors(citationData.author) }
      : citationData;
    const keys = Object.keys(citationForms[citation.citationType].fields);
    const obj: any = {};

    for (const key of keys) {
      if (citationDataAuthorsProcessed.hasOwnProperty(key)) {
        obj[key] = (citationDataAuthorsProcessed as any)[key];
      }
    }
    const filteredCitationData = obj as CitationData;

    filteredCitationData.id = citation.data.id;
    filteredCitationData.type = citation.data.type;
    return { ...citation, data: filteredCitationData };
  });

  return newCitationDataArray;
};

// Checks through authors and removes those that have no fields
// Note: this processing is needed for citeproc to fully support authorless in-text citations.
const processAuthors = (authors: Name[]): Name[] => {
  return authors.filter((author) => {
    return !authorDoesNotExist(author);
  });
};

// Returns extracted in-text citations from citeproc bibliography object.
// citeproc setup fn should have been called before calling this
export const getInTextCitations = (
  citationIDs: string[],
  modifiers?: CiteProcModifiers,
): string[] => {
  const inTextCitationItems: CiteProcRebuildProcessorOutput =
    citeproc.rebuildProcessorState(
      citationIDs.map((citationID) => ({
        citationID,
        citationItems: [{ id: citationID, ...modifiers }],
      })),
    );
  // TODO consider replacing '[NO_PRINTED_FORM]' items with undefined
  return inTextCitationItems
    .filter((item) => !item.includes('CSL STYLE ERROR: '))
    .map((item) => item[2]);
};

export const getCSLCitationStyle = (): { name: string; xml: string } => {
  return currentStyle;
};

export const getCitationLocation = (): CitationLocation => {
  if (citeproc && citeproc.cslXml.dataObj.attrs.class === 'note') {
    return CitationLocation.Footnote;
  } else {
    return CitationLocation.Intext;
  }
};

export const isCurrentStyleSortingBibliography = (): boolean => {
  return !!citeproc?.cslXml.dataObj.children
    .find((child: { name: string }) => child.name === 'bibliography')
    ?.children.find((child: { name: string }) => child.name === 'sort');
};

export default getCitations;
