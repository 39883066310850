import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import createApiUrl from 'lib/create-api-url';
import { clearSession } from 'redux/modules/auth-module';
import { AppDispatch } from 'redux/store';
import { ResponseCitationList } from 'types/citation-list';
import createWebUrl from 'lib/create-web-url';

export interface Me extends ResponseCitationList {
  gdocsExport: boolean;
  email: string;
}

export const getMe = (
  cookies?: string,
  requestId?: string,
): Promise<AxiosResponse<Me>> => {
  const headers: AxiosRequestConfig['headers'] = {};
  if (cookies) {
    headers['Cookie'] = cookies;
  }
  if (requestId) {
    headers['x-request-id'] = requestId;
  }
  return axios.get(createApiUrl(`/auth/me`), {
    headers,
  });
};

export const logout = (): Promise<AxiosResponse> => {
  return axios.post(createApiUrl(`/auth/logout`));
};

export const login = (
  email: string,
  password: string,
): Promise<AxiosResponse<Me>> => {
  return axios.post(createApiUrl(`/auth/login`), { email, password });
};

export const signup = (
  email: string,
  password: string,
): Promise<AxiosResponse<Me>> => {
  return axios.post(createApiUrl(`/auth/signup`), { email, password });
};

export const sendResetPasswordEmail = (
  email: string,
): Promise<AxiosResponse> => {
  return axios.post(createApiUrl('/auth/reset-password'), { email });
};

export const resetPassword = (
  token: string,
  password: string,
): Promise<AxiosResponse<Me>> => {
  return axios.post(createApiUrl(`/auth/reset-password/${token}`), {
    password,
  });
};

export const setGdocsExport = (
  gdocsExport: boolean,
): Promise<AxiosResponse> => {
  return axios.put(createApiUrl(`/auth/gdocs-export`), {
    gdocsExport,
  });
};

const isBibcitationUrl = (url?: string): boolean =>
  [createWebUrl(), createApiUrl(''), '/'].some((base) => url?.startsWith(base));

export const setupAxios = (dispatch: AppDispatch): void => {
  axios.interceptors.request.use((config) => {
    if (!isBibcitationUrl(config.url)) {
      return config;
    }
    config.withCredentials = true;
    return config;
  });
  axios.interceptors.response.use(undefined, (error) => {
    if (
      !isBibcitationUrl(error?.response?.config?.url) ||
      error?.response?.status !== 401
    ) {
      return Promise.reject(error);
    }
    dispatch(clearSession());
    return Promise.reject(error);
  });
};
