import React from 'react';

const Newspaper: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg height="16" viewBox="0 0 18 14" fill="none" {...props}>
    <path
      d="M15.5 13C17 13 17 11.5 17 11.5V2H14M15.5 13C14 13 14 11.5 14 11.5V2M15.5 13C15.5 13 5.68633 13 3.28645 13C0.886585 13 1.00086 10.6 1.00086 10.6V1H14V2"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 7L7 7" stroke="currentColor" strokeLinecap="round" />
    <path d="M9 7H11" stroke="currentColor" strokeLinecap="round" />
    <path d="M4 4L11 4" stroke="currentColor" strokeLinecap="round" />
    <path d="M4 9L11 9" stroke="currentColor" strokeLinecap="round" />
  </svg>
);

export default Newspaper;
