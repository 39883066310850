import Cookies from 'js-cookie';
// import { range } from 'lib/seeded-random';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Tracking from 'lib/tracking';

export const prefix = '_react_reduxtk_ab_';

export const experiments = {
  /*'2020-10-02-default-citation-style': {
    id: '2020-10-02-default-citation-style',
    choose: chooseRandomly,
    variants: {
      'Modern Language Association 8th edition': {
        name: 'Modern Language Association 8th edition',
      },
      'American Psychological Association 7th edition': {
        name: 'American Psychological Association 7th edition',
      },
    },
  },*/
  '2021-10-27-chrome-extension-add-button': {
    id: '2021-10-27-chrome-extension-add-button',
    variants: {
      button: {
        name: 'button',
      },
      noButton: {
        name: 'noButton',
      },
    },
  },
  '2023-10-20-search-empty-contact-us-cta': {
    id: '2023-10-20-search-empty-contact-us-cta',
    variants: {
      hasCta: {
        name: 'hasCta',
      },
      noCta: {
        name: 'noCta',
      },
    },
  },
};

export interface Variant {
  name: string;
  weight?: number;
}
interface Variants {
  [key: string]: Variant;
}

export type ExperimentKeys = keyof typeof experiments;

export interface Experiment {
  id: string;
  choose?: (variants: Variants, ip?: string) => string;
  variants: Variants;
}

type Experiments = {
  [key: string]: Experiment;
};

export function getExperimentCookies(): Partial<{ [key: string]: string }> {
  const cookies = Cookies.get();
  const currentExperiments: Partial<{ [key: string]: string }> = {};
  for (const key in cookies) {
    if (key.indexOf(prefix) === 0) {
      const name = key.slice(prefix.length);
      if (name in experiments) {
        currentExperiments[name] = cookies[key];
      }
    }
  }
  return currentExperiments;
}

export function setExperimentCookies(state: Record<string, string>): void {
  for (const experiment in state) {
    Cookies.set(prefix + experiment, state[experiment], { expires: 1461 });
  }
}

export function chooseRandomly(variants: Variants): string {
  const weighedVariants: Variant[] = [];
  for (const key in variants) {
    const variant = variants[key];
    const weight = variant.weight || 1;
    for (let i = 0; i < weight; i++) {
      weighedVariants.push(variant);
    }
  }
  return weighedVariants[Math.floor(Math.random() * weighedVariants.length)]
    .name;
}

function getRandomVariant(experiment: Experiment, ip?: string): string {
  const choose = experiment.choose || chooseRandomly;
  return choose(experiment.variants, ip);
}

/*
function ipToInt(ip: string): number {
  return (
    ip.split('.').reduce(function (ipInt, octet) {
      return (ipInt << 8) + parseInt(octet, 10);
    }, 0) >>> 0
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
 function getVariantFromIp(variants: Variants, ip: string): string {
  const weighedVariants: Variant[] = [];
  for (const key in variants) {
    const variant = variants[key];
    const weight = variant.weight || 1;
    for (let i = 0; i < weight; i++) {
      weighedVariants.push(variant);
    }
  }
  const variantToChoose = range(ipToInt(ip), 0, weighedVariants.length - 1);
  return weighedVariants[variantToChoose].name;
} */

function generateRandomState(
  experiments: Experiments,
  ip?: string,
): Partial<{ [key: string]: string }> {
  const initialState: Partial<{ [key: string]: string }> = {};

  for (const name in experiments) {
    initialState[name] = getRandomVariant(experiments[name], ip);
  }
  return initialState;
}

export type ABTestState = Readonly<Record<ExperimentKeys | string, string>>;

export const initialState: ABTestState = {};

export const abTestSlice = createSlice({
  name: 'abtest',
  initialState,
  reducers: {
    initializeExperimentVariants: (state, action: PayloadAction<string>) => {
      const initValues = getExperimentCookies() as { [key: string]: string };
      const randomState = generateRandomState(experiments, action.payload) as {
        [key: string]: string;
      };
      const abTestGroups: Record<string, string> = {};
      for (const key in randomState) {
        if (initValues.hasOwnProperty(key)) {
          state[key as ExperimentKeys] = initValues[key as ExperimentKeys];
        } else {
          state[key as ExperimentKeys] = randomState[key as ExperimentKeys];
        }
        abTestGroups[experiments[key as ExperimentKeys].id] =
          state[key as ExperimentKeys];
      }
      Tracking.setAbTestGroups(abTestGroups);
      setExperimentCookies(state);
      return state;
    },
  },
});

export const { initializeExperimentVariants } = abTestSlice.actions;

export default abTestSlice.reducer;
