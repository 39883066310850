import React from 'react';

const Book: React.FC<React.SVGProps<SVGSVGElement>> = (props): JSX.Element => (
  <svg height="16" viewBox="0 0 18 14" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 13V2.09091C9 2.09091 7.22222 0.999996 5 0.999996C2.77778 0.999996 1 2.09091 1 2.09091V13C1 13 2.77778 11.9091 5 11.9091C7.22222 11.9091 9 13 9 13Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 4.27613C3.58707 4.11788 4.26952 4 5 4C5.73048 4 6.41293 4.11788 7 4.27613"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 4.27613C11.5871 4.11788 12.2695 4 13 4C13.7305 4 14.4129 4.11788 15 4.27613"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 6.27613C3.58707 6.11788 4.26952 6 5 6C5.73048 6 6.41293 6.11788 7 6.27613"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 6.27613C11.5871 6.11788 12.2695 6 13 6C13.7305 6 14.4129 6.11788 15 6.27613"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 8.27613C3.58707 8.11788 4.26952 8 5 8C5.73048 8 6.41293 8.11788 7 8.27613"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 8.27613C11.5871 8.11788 12.2695 8 13 8C13.7305 8 14.4129 8.11788 15 8.27613"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13V2.09091C17 2.09091 15.2222 1 13 1C10.7778 1 9 2.09091 9 2.09091V13C9 13 10.7778 11.9091 13 11.9091C15.2222 11.9091 17 13 17 13Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Book;
