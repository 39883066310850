import yn from 'yn';

const createApiUrl = (url = '/'): string => {
  const windowDefined = typeof window !== 'undefined';
  const apiUrlFromAppScript =
    yn(process.env.GDOCS) && windowDefined && window.bibcitationApiUrl;

  const prUrl = windowDefined
    ? `https://${process.env.HEROKU_APP_NAME?.replace(
        '-web-',
        '-api-',
      )}.herokuapp.com`
    : '/api';

  return `${apiUrlFromAppScript || process.env.API_URL || prUrl}${url}`;
};

export default createApiUrl;
