import React from 'react';

const Canvas: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg height="16" viewBox="0 0 18 16" fill="none" {...props}>
    <path
      d="M17 1H1V15H17V1Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 4H14M4 4V12M4 4L1 1M14 4V12M14 4L17 1M14 12H4M14 12L17 15M4 12L1 15"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Canvas;
