import createWebUrl from 'lib/create-web-url';
import { NextRouter } from 'next/router';
import { Citation } from 'redux/modules/citation-module';
import { getRequestData } from './document-store';

export const citationsFromRouterQuery = (
  query: NextRouter['query'],
): Citation[] => {
  const citationsJSON = query.citations as string;
  if (!citationsJSON) {
    return [];
  }
  try {
    const citations = JSON.parse(citationsJSON);
    return citations as Citation[];
  } catch (error) {
    if (error instanceof SyntaxError) {
      return [];
    }
    throw error;
  }
};

export const makeEditOnBibcitationUrl = (): string => {
  const { gdocId, token } = getRequestData();
  return createWebUrl(
    `/?gdocId=${encodeURIComponent(gdocId)}&token=${encodeURIComponent(token)}`,
  );
};

export const runningInGdocs = (): boolean => {
  return typeof (window as any)?.google?.script?.run !== 'undefined';
};

export const callAppScript = (fun: string, ...args: any[]): Promise<any> => {
  return new Promise((resolve, reject) => {
    const google = (window as any)?.google;
    google.script.run
      .withFailureHandler(reject)
      .withSuccessHandler(resolve)
      [fun](...args);
  });
};
