import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as CitationListModel from 'models/citation-list-model';
import { HYDRATE } from 'next-redux-wrapper';
import { setUserData, login, logout, signup } from './auth-module';
import { CitationStyle } from 'types/citation-style';
import { AppStore, Store } from 'redux/store';
import axios from 'axios';
import {
  DEFAULT_CITATION_STYLE,
  DEFAULT_LIST_NAME,
  DEFAULT_STYLE_KEY,
} from 'lib/citation-defaults';
import {
  CurrentCitationListInfo,
  ListMetadata,
  ResponseCitationList,
} from 'types/citation-list';
import { reportGeneralException } from 'lib/sentry';
import { v4 as uuidv4 } from 'uuid';
import * as wordStore from 'lib/word/store';
import { HydrateAction } from 'types/hydrate-action';
import * as CitationModule from './citation-module';

const DEFAULT_LIST_INDEX = 0;
const citationListPrefix = 'citationList';

export type CitationListState = Readonly<{
  lists: ListMetadata[];
  currentListIndex: number;
}>;

export const initialState: CitationListState = {
  lists: [],
  currentListIndex: -1,
};

const findCurrentCitationIndex = (
  citationLists: ListMetadata[],
  currentCitations?: CurrentCitationListInfo,
): number => {
  if (!currentCitations) return DEFAULT_LIST_INDEX;
  const index = citationLists.findIndex((citationList) => {
    return currentCitations.id === citationList.id;
  });
  return index === -1 ? DEFAULT_LIST_INDEX : index;
};

// todo: Improve types
const updateListsFromBackend = (
  state: any,
  action: PayloadAction<ResponseCitationList, string, unknown, never>,
): void => {
  const payload = action.payload;
  if (!payload.citationLists) {
    reportGeneralException(
      'Could not get citations lists from backend so using defaults',
      payload,
    );
  } else {
    state.lists = payload.citationLists;
    state.currentListIndex = findCurrentCitationIndex(
      payload.citationLists,
      payload.currentCitations,
    );
  }
};

// Moved here to avoid circular dependency w/ citation-module
export const setCitationStyle = createAction<CitationStyle>(
  `${citationListPrefix}/setCitationStyle`,
);

export const deleteCitationList = createAsyncThunk(
  `${citationListPrefix}/deleteCitationList`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await CitationListModel.deleteCitationList(id);
      return response.data;
    } catch (error) {
      console.error('Error deleting list');
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
      // Should still reject in future cases
      return rejectWithValue(undefined);
    }
  },
);

export const duplicateCurrentList = createAsyncThunk(
  `${citationListPrefix}/duplicateCitationList`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState() as Store;
      const response = await CitationListModel.duplicateCurrentList(state);
      return response;
    } catch (error) {
      console.error('Error duplicating list');
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
      // Should still reject in future cases
      return rejectWithValue(undefined);
    }
  },
);

export const updateCitationListName = createAsyncThunk(
  `${citationListPrefix}/updateCitationListName`,
  async ({ id, name }: { id: string; name: string }, { rejectWithValue }) => {
    try {
      const response = await CitationListModel.updateCitationListName(id, name);
      return response.data;
    } catch (error) {
      console.error('Error updating citation list name');
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
      // Should still reject in future cases
      return rejectWithValue(undefined);
    }
  },
);

export const addGdocCitationsToCurrentList = createAsyncThunk(
  `${citationListPrefix}/addGdocCitationsToCurrentList`,
  async (
    { gdocId, token }: { gdocId: string; token: string },
    { getState },
  ) => {
    const state = getState() as Store; // dunno why it has to be done this way
    const currentListIndex = state.citationList.currentListIndex;
    const currentListMetadata = state.citationList.lists[currentListIndex];

    const response = await CitationListModel.addGdocCitationsToCurrentList(
      gdocId,
      token,
      currentListMetadata.id,
    );
    return response.data;
  },
);

interface LoadListInput {
  listIDToLoad: string;
  store: AppStore;
}

export const loadCitationList = createAsyncThunk(
  `${citationListPrefix}/loadCitationList`,
  async (listInput: LoadListInput) => {
    const savePromise = CitationListModel.saveCurrentList(listInput.store);
    const loadPromise = CitationListModel.loadList(listInput.listIDToLoad);
    const [, loadResponse] = await Promise.all([savePromise, loadPromise]);
    return loadResponse.data;
  },
);

export const newCitationList = createAsyncThunk(
  `${citationListPrefix}/newCitationList`,
  async (styleKey: string) => {
    const response = await CitationListModel.newList(styleKey);
    return response.data;
  },
);

export const enableSharingForCurrentList = createAsyncThunk(
  `${citationListPrefix}/enableSharingForCurrentList`,
  async (undefined, { getState }) => {
    const { lists, currentListIndex } = (getState() as Store).citationList;
    const listId = lists[currentListIndex].id;
    const response = await CitationListModel.enableSharing(listId);
    return response.data;
  },
);

export const disableSharingForCurrentList = createAsyncThunk(
  `${citationListPrefix}/disableSharingForCurrentList`,
  async (undefined, { getState }) => {
    const { lists, currentListIndex } = (getState() as Store).citationList;
    const listId = lists[currentListIndex].id;
    await CitationListModel.disableSharing(listId);
  },
);

export const toggleAllowEditing = createAsyncThunk(
  `${citationListPrefix}/toggleAllowEditing`,
  async (undefined, { getState }) => {
    const { lists, currentListIndex } = (getState() as Store).citationList;
    const { id, allowEditing } = lists[currentListIndex];
    await CitationListModel.updateSharing(id, {
      allowEditing: !allowEditing,
    });
  },
);

export const loadWordCitationList = createAsyncThunk(
  `${citationListPrefix}/loadWordCitationList`,
  async () => {
    const list = await wordStore.getCitationList();

    if (!list) {
      const newList = {
        id: uuidv4(),
        citations: [],
        style: DEFAULT_CITATION_STYLE,
      };
      await wordStore.setCitationList(newList);
      return newList;
    }

    return list;
  },
);

export const citationListSlice = createSlice({
  name: citationListPrefix,
  initialState: initialState,
  reducers: {
    updateCurrentListID: (state, action) => {
      const currentList = state.lists[state.currentListIndex];
      currentList.id = action.payload;
    },
    setGdocsList: (state, action) => {
      state.lists = [
        {
          id: uuidv4(),
          name: DEFAULT_LIST_NAME,
          styleKey: action.payload.styleKey,
          allowEditing: false,
          collaborators: [],
          length: 0,
        },
      ];
      state.currentListIndex = DEFAULT_LIST_INDEX;
    },
    setDefaultList: (state) => {
      state.lists = [
        {
          id: uuidv4(),
          name: DEFAULT_LIST_NAME,
          styleKey: DEFAULT_STYLE_KEY,
          allowEditing: false,
          collaborators: [],
          length: 0,
        },
      ];
      state.currentListIndex = DEFAULT_LIST_INDEX;
    },
  },
  extraReducers: (builder) => {
    //@ts-ignore
    builder.addCase(setUserData, updateListsFromBackend);
    builder.addCase(login.fulfilled, updateListsFromBackend);
    builder.addCase(signup.fulfilled, updateListsFromBackend);
    builder.addCase(deleteCitationList.fulfilled, updateListsFromBackend);
    builder.addCase(logout.fulfilled, (state, action) => {
      state.lists = [action.payload.list];
      state.currentListIndex = DEFAULT_LIST_INDEX;
    });
    builder.addCase(loadCitationList.fulfilled, (state, action) => {
      state.currentListIndex = findCurrentCitationIndex(
        state.lists,
        action.payload,
      );
    });
    builder.addCase(
      addGdocCitationsToCurrentList.fulfilled,
      (state, action) => {
        state.currentListIndex = findCurrentCitationIndex(
          state.lists,
          action.payload,
        );
      },
    );
    builder.addCase(duplicateCurrentList.fulfilled, (state, action) => {
      state.lists.push({
        ...action.payload,
        allowEditing: false,
        collaborators: [],
        length: action.payload.citations.length,
      });
      state.currentListIndex = state.lists.length - 1;
    });
    builder.addCase(newCitationList.fulfilled, (state, action) => {
      const newList = {
        name: action.payload.name,
        citations: [],
        styleKey: action.payload.style.name,
        id: action.payload.id,
        shareId: null,
        allowEditing: false,
        collaborators: [],
        length: 0,
      };

      state.lists.push(newList);
      state.currentListIndex = state.lists.length - 1;
    });
    builder.addCase(updateCitationListName.fulfilled, (state, action) => {
      const index = state.lists.findIndex(
        (list) => list.id === action.payload.id,
      );
      state.lists[index].name = action.payload.name;
    });
    builder.addCase(setCitationStyle, (state, action) => {
      const currentMetadata = state.lists[state.currentListIndex];
      currentMetadata.styleKey = action.payload.name;
    });
    builder.addCase(enableSharingForCurrentList.fulfilled, (state, action) => {
      const currentList = state.lists[state.currentListIndex];
      currentList.shareId = action.payload.shareId;
    });
    builder.addCase(disableSharingForCurrentList.fulfilled, (state) => {
      const currentList = state.lists[state.currentListIndex];
      currentList.shareId = null;
    });
    builder.addCase(toggleAllowEditing.fulfilled, (state) => {
      const currentList = state.lists[state.currentListIndex];
      currentList.allowEditing = !currentList.allowEditing;
    });
    builder.addCase(loadWordCitationList.fulfilled, (state, action) => {
      state.lists = [
        {
          id: action.payload.id,
          name: DEFAULT_LIST_NAME,
          styleKey: action.payload.style.name,
          allowEditing: false,
          shareId: null,
          collaborators: [],
          length: 0,
        },
      ];
      state.currentListIndex = DEFAULT_LIST_INDEX;
    });
    builder.addCase(HYDRATE, (state, action: HydrateAction) => {
      state.lists = action.payload.citationList.lists;
      state.currentListIndex = action.payload.citationList.currentListIndex;
    });
    builder.addCase(CitationModule.startNewCitation, (state) => {
      const currentList = state.lists[state.currentListIndex];
      currentList.length += 1;
    });
    builder.addCase(CitationModule.deleteCitation.fulfilled, (state) => {
      const currentList = state.lists[state.currentListIndex];
      currentList.length -= 1;
    });
    builder.addCase(CitationModule.deleteCurrentCitation, (state) => {
      const currentList = state.lists[state.currentListIndex];
      currentList.length -= 1;
    });
  },
});

export const { updateCurrentListID, setGdocsList, setDefaultList } =
  citationListSlice.actions;

export default citationListSlice.reducer;
