enum CitationTypes {
  'artwork' = 'artwork',
  'blogPost' = 'blogPost',
  'book' = 'book',
  'bookReview' = 'bookReview',
  'bookChapter' = 'bookChapter',
  'conferencePaper' = 'conferencePaper',
  'database' = 'database',
  'dictionaryEntry' = 'dictionaryEntry',
  'document' = 'document',
  'ebook' = 'ebook',
  'encyclopediaEntry' = 'encyclopediaEntry',
  'filmOrMovie' = 'filmOrMovie',
  'image' = 'image',
  'interview' = 'interview',
  'journalArticle' = 'journalArticle',
  'legalCase' = 'legalCase',
  'legalBill' = 'legalBill',
  'legislation' = 'legislation',
  'magazineArticle' = 'magazineArticle',
  'map' = 'map',
  'manuscript' = 'manuscript',
  'newsArticle' = 'newsArticle',
  'patent' = 'patent',
  'personalCommunication' = 'personalCommunication',
  'report' = 'report',
  'review' = 'review',
  'song' = 'song',
  'speech' = 'speech',
  'thesis' = 'thesis',
  'tvRadioBroadcast' = 'tvRadioBroadcast',
  'video' = 'video',
  'website' = 'website',
}

export default CitationTypes;
