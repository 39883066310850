import createApiUrl from 'lib/create-api-url';
import { v4 as uuidv4 } from 'uuid';
import axios, { AxiosResponse } from 'axios';
import { AppStore, Store } from 'redux/store';
import {
  ClientCitationList,
  CurrentCitationListInfo,
  ResponseCitationList,
} from 'types/citation-list';
import * as GdocsDocumentStore from 'lib/gdocs/document-store';
import { gdocHeadersFromToken, getRequestData } from 'lib/gdocs/document-store';
import { reportGeneralException } from 'lib/sentry';
import { isAllowedToEdit } from 'redux/selectors/citation-list-selector';
import { publishListChange } from 'lib/inter-tab-communication';
import { runningInGdocs } from 'lib/gdocs';
import * as wordStore from 'lib/word/store';
import { runningInWord } from 'lib/word/running-in-word';
import { Citation } from 'redux/modules/citation-module';
import { CitationStyle } from 'types/citation-style';
import yn from 'yn';
const PATH_PREFIX = '/citation-list';

export const deleteCitationList = (
  id: string,
): Promise<AxiosResponse<ResponseCitationList>> => {
  return axios.delete(createApiUrl(`${PATH_PREFIX}/${id}`));
};

export const updateCitationListName = async (
  id: string,
  name: string,
): Promise<AxiosResponse<ClientCitationList>> => {
  return axios.put(createApiUrl(`${PATH_PREFIX}/${id}/name`), { name });
};

export const duplicateCurrentList = async (
  state: Store,
): Promise<ClientCitationList> => {
  const currentListIndex = state.citationList.currentListIndex;
  const currentListMetadata = state.citationList.lists[currentListIndex];
  try {
    const newList = await axios.post(
      createApiUrl(`${PATH_PREFIX}/${currentListMetadata.id}/duplicate`),
    );
    return newList.data;
  } catch (error) {
    reportGeneralException(
      'Had an error trying to duplicate list',
      currentListMetadata,
    );
    throw error;
  }
};

let prevCount = -1;

export const saveCurrentList = async (store: AppStore): Promise<void> => {
  // do not store list when running as chrome extension
  // indirect, but simple solution
  if (/chrome-extension:/.test(window.location.href)) {
    return;
  }
  const state = store.getState();
  if (state.citation.editCount === prevCount) {
    return;
  }
  prevCount = state.citation.editCount;

  const currentListIndex = state.citationList.currentListIndex;
  const currentListMetadata = state.citationList.lists[currentListIndex];
  const email = state.auth.data?.email;
  const allowedToEdit = isAllowedToEdit(currentListMetadata, email);

  if (!allowedToEdit) {
    return;
  }

  const citationList: ClientCitationList = {
    id: currentListMetadata.id,
    name: currentListMetadata.name,
    styleKey: currentListMetadata.styleKey,
    citations: state.citation.citations,
  };

  if (runningInWord()) {
    await updateWordList({
      id: citationList.id,
      citations: citationList.citations,
      style: state.citation.citationStyle,
    });
    return;
  }

  if (/gdocs$/.test(window.location.href) || yn(process.env.GDOCS)) {
    await GdocsDocumentStore.setState({
      styleKey: state.citation.citationStyle.name,
      citations: citationList.citations,
    });
    publishListChange({
      id: citationList.id,
      citations: citationList.citations,
      style: state.citation.citationStyle,
    });
    return;
  }

  const reqId = uuidv4();
  try {
    await axios.put(
      createApiUrl(`${PATH_PREFIX}/${citationList.id}`),
      {
        list: citationList,
      },
      { headers: { 'X-Request-Id': reqId } },
    );
    publishListChange({
      id: citationList.id,
      citations: citationList.citations,
      style: state.citation.citationStyle,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorData = error.response?.data?.error;
      const code = errorData?.code;
      const serverListIds = errorData?.listIds;
      if (code === 'ONE_GUEST_LIST_ONLY') {
        reportGeneralException(
          'UI trying to create another list for guest via PUT',
          {
            gdocs: yn(process.env.GDOCS),
            clientListIds: state.citationList.lists.map((list) => list.id),
            serverListIds,
            serverEndUserId: errorData?.serverEndUserId,
            status: state.auth.status,
            reqId,
            originalRequestId: state.auth.originalRequestId,
          },
        );
      } else if (code === 'DELETING_TOO_MANY_CITATIONS') {
        reportGeneralException('UI trying to delete too many citations', {
          reqId,
          originalRequestId: state.auth.originalRequestId,
        });
      } else {
        reportGeneralException(
          'Had an error trying to save current list on web',
          {
            citationList,
            error: error,
            errorCode: error?.code,
            errorJSON: error?.toJSON(),
          },
        );
      }
      throw error;
    }
  }
};

export const loadList = (
  listID: string,
): Promise<AxiosResponse<CurrentCitationListInfo>> => {
  return axios.get(createApiUrl(`${PATH_PREFIX}/${listID}`));
};

export const newList = async (
  styleKey: string,
): Promise<AxiosResponse<CurrentCitationListInfo>> => {
  const reqId = uuidv4();
  try {
    return await axios.post(
      createApiUrl(`${PATH_PREFIX}`),
      {
        styleKey,
      },
      { headers: { 'X-Request-Id': reqId } },
    );
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const code = error.response?.data?.error?.code;
      if (code === 'ONE_GUEST_LIST_ONLY') {
        reportGeneralException(
          'UI trying to create another list for guest via POST',
          {
            reqId,
          },
        );
      }
      throw error;
    }
    throw error;
  }
};

export const addGdocCitationsToCurrentList = (
  gdocId: string,
  token: string,
  newId: string,
): Promise<AxiosResponse<CurrentCitationListInfo>> => {
  return axios.post(
    createApiUrl(
      `${PATH_PREFIX}/gdoc/${gdocId}/add-to-current?newId=${encodeURIComponent(
        newId,
      )}`,
    ),
    undefined,
    { headers: gdocHeadersFromToken(token) },
  );
};

export const enableSharing = (
  listId: string,
): Promise<AxiosResponse<{ shareId: string }>> => {
  return axios.post(createApiUrl(`${PATH_PREFIX}/${listId}/sharing`));
};

export const disableSharing = (listId: string): Promise<AxiosResponse> => {
  return axios.delete(createApiUrl(`${PATH_PREFIX}/${listId}/sharing`));
};

export const updateSharing = (
  listId: string,
  sharing: { allowEditing: boolean },
): Promise<AxiosResponse> => {
  return axios.put(createApiUrl(`${PATH_PREFIX}/${listId}/sharing`), sharing);
};

export const getSharingInvitation = (
  shareId: string,
): Promise<
  AxiosResponse<{
    owner: string;
    listName: string;
    collaborators: string[];
    id: string;
  }>
> => {
  return axios.get(createApiUrl(`${PATH_PREFIX}/invitations/${shareId}`));
};

export const acceptSharingInvitation = (
  shareId: string,
): Promise<AxiosResponse> => {
  return axios.post(
    createApiUrl(`${PATH_PREFIX}/invitations/${shareId}/accept`),
  );
};

export const deleteCitation = async (
  listId: string,
  citationId: string,
): Promise<AxiosResponse | void> => {
  if (runningInGdocs()) {
    const { gdocId, token } = getRequestData();
    return axios.delete(
      createApiUrl(`${PATH_PREFIX}/gdoc/${gdocId}/citations/${citationId}`),
      { headers: gdocHeadersFromToken(token) },
    );
  }

  if (runningInWord()) {
    return deleteWordCitation(citationId);
  }

  return axios.delete(
    createApiUrl(
      `${PATH_PREFIX}/${listId}/citations/${encodeURIComponent(citationId)}`, // the encodeURIComponent is important because of old citations having ids that matched their DOIs. for instance and id like 10.2307/40091004
    ),
  );
};

export const createWordListForEditing = async (
  citations: Citation[],
): Promise<string> => {
  const wordId = uuidv4();
  await axios.post(createApiUrl(`${PATH_PREFIX}/word`), { wordId, citations });
  return wordId;
};

const updateWordList = async (list: {
  id: string;
  style: CitationStyle;
  citations: Citation[];
}): Promise<void> => {
  await wordStore.setCitationList(list);
};

export const getWordLegacyCitations = async (): Promise<
  AxiosResponse<Citation[]>
> => {
  const wordId = await wordStore.getDocumentId();
  return axios.get(createApiUrl(`${PATH_PREFIX}/word/${wordId}/citations`));
};

const deleteWordCitation = async (citationId: string): Promise<void> => {
  const list = await wordStore.getCitationList();
  if (!list) return;
  list.citations = list.citations.filter((c) => c.data.id !== citationId);
  await wordStore.setCitationList(list);
};

export const addWordCitationsToCurrentList = (
  wordId: string,
): Promise<AxiosResponse<CurrentCitationListInfo>> => {
  return axios.post(
    createApiUrl(`${PATH_PREFIX}/word/${wordId}/add-to-current`),
  );
};
