import {
  Citation,
  RequiredFieldsMetadata,
} from 'redux/modules/citation-module';
import { getFieldMetadata } from 'types/citation-forms';
import CitationTypes from 'types/citation-types';
import { CitationFieldKey } from 'types/fields';
import FormField from 'types/form-field';
import isCitationFieldValEmpty from 'lib/is-citation-field-empty';

// Sets all required fields as missing for a given type
export const initializeRequiredFieldsMetadata = (
  type: CitationTypes,
): RequiredFieldsMetadata => {
  const fieldDetails = getFieldMetadata(type);
  const result: RequiredFieldsMetadata = {
    ignoredFields: {},
    missingFields: {},
  };

  for (const field in fieldDetails) {
    const key = field as CitationFieldKey;
    const metadata = fieldDetails[key];
    if (!metadata || !metadata.isRequired) continue;
    const name = metadata.missingFieldLabel
      ? metadata.missingFieldLabel
      : metadata.label;
    result.missingFields[name] = key;
  }
  return result;
};

// Reinitializes the missing fields by looping over all of the new type's  required fields
// and checking if they're present. Ignored fields carry over if they exist.
// Intended to be called in citation-module's setCitationType.
export const reinitializeRequiredFieldsMetadata = (
  citation: Citation,
  newType: CitationTypes,
): RequiredFieldsMetadata => {
  const oldRequiredFieldMetadata = citation.requiredFieldsMetadata;
  const citationData = citation.data;
  const newRequiredFieldsMetadata: RequiredFieldsMetadata = {
    ignoredFields: oldRequiredFieldMetadata.ignoredFields,
    missingFields: {},
  };

  const newTypeFieldMetadata = getFieldMetadata(newType);
  for (const key in newTypeFieldMetadata) {
    const fieldName = key as CitationFieldKey;
    const metadata = newTypeFieldMetadata[fieldName];
    if (!metadata) continue;

    const missingFieldLabel = generateMissingFieldLabel(metadata);

    if (
      !metadata.isRequired ||
      newRequiredFieldsMetadata.ignoredFields[missingFieldLabel]
    ) {
      continue;
    }

    if (isCitationFieldValEmpty(citationData[fieldName])) {
      newRequiredFieldsMetadata.missingFields[missingFieldLabel] = fieldName;
    }
  }

  return newRequiredFieldsMetadata;
};

// Creates the display name for the missing field
export const generateMissingFieldLabel = (fieldMetadata: FormField): string => {
  return fieldMetadata.missingFieldLabel
    ? fieldMetadata.missingFieldLabel
    : fieldMetadata.label;
};
