import axios, { AxiosResponse } from 'axios';
import createApiUrl from 'lib/create-api-url';
import createScraperUrl from 'lib/create-scraper-url';
import CitationTypes from 'types/citation-types';
import { CitationDataLoose } from 'types/fields';
import {
  JournalResponse,
  BookResponse,
  StylesResponse,
} from 'types/network-responses';

export const RESULTS_PER_PAGE_BOOK = 10;

export const searchJournalMetadataGeneral = (
  search: string,
  page: number,
  citationType: CitationTypes,
): Promise<AxiosResponse<JournalResponse>> => {
  return axios.get(
    createScraperUrl(
      `/journal-search?&search=${encodeURIComponent(
        search,
      )}&page=${page}&type=${citationType}`,
    ),
  );
};

export const searchJournalMetadataDoi = (
  doi: string,
): Promise<AxiosResponse<CitationDataLoose>> => {
  return axios.get(
    createScraperUrl(`/journal-search?&doi=${encodeURIComponent(doi)}`),
  );
};

let got429 = false;

export const searchBookMetadata = async (
  search: string,
  page: number,
): Promise<AxiosResponse<BookResponse>> => {
  let response: AxiosResponse<any, any>;
  try {
    if (got429) {
      throw new Error('Too many requests on client');
    }
    response = await axios.get(
      `https://www.googleapis.com/books/v1/volumes?q=${encodeURIComponent(
        search,
      )}&startIndex=${RESULTS_PER_PAGE_BOOK * page}`,
    );
    // sometimes google api response doesn't contain items - causes issues with undefined values later if not handled
    if ('items' in response.data) return response;
    return {
      ...response,
      data: {
        ...response.data,
        items: [],
      },
    };
  } catch (e: any) {
    got429 = true;
    response = await axios.get(
      createScraperUrl(
        '/book-search?&search=' +
          encodeURIComponent(search) +
          '&startIndex=' +
          page * RESULTS_PER_PAGE_BOOK,
      ),
    );
  }
  return response;
};

export const searchWebsiteMetadata = (
  url: string,
): Promise<AxiosResponse<unknown>> => {
  return axios.get(
    createScraperUrl(`/website-data?url=${encodeURIComponent(url)}`),
  );
};

export class SearchWebsiteMetadataProgressTask {
  source: EventSource | undefined;

  run(url: string, onProgress: (progress: number) => void): Promise<any> {
    this.source?.close();
    const sourceUrl = createScraperUrl(
      `/website-data/progress?url=${encodeURIComponent(url)}`,
    );
    return new Promise((resolve, reject) => {
      const source = new EventSource(sourceUrl);
      this.source = source;
      source.onerror = (error) => {
        source.close();
        reject(error);
      };
      source.addEventListener('progress', (event) => {
        const progress = JSON.parse(event.data).progress;
        onProgress(progress);
      });
      source.addEventListener('result', (event) => {
        source.close();
        const result = JSON.parse(event.data);
        if (result.err) {
          const error = new Error('Failed to scrape');
          (error as any).response = {
            status: result.status,
            data: result.body,
          };
          reject(error);
          return;
        }
        resolve({ data: result.body });
      });
    });
  }
}

function calculateProgress(loaded: number, total: number): number {
  return Math.round((loaded * 100) / total);
}

export const searchWebsiteMetadataFromHtml = (
  html: string,
  url: string,
  onProgress?: (progress: number) => void,
): Promise<AxiosResponse<CitationDataLoose>> => {
  return axios.post(
    createScraperUrl(`/website-data/files/${encodeURIComponent(url)}`),
    html,
    {
      headers: { 'Content-Type': 'text/html' },
      onUploadProgress: ({ loaded, total }) => {
        onProgress?.(calculateProgress(loaded, total));
      },
    },
  );
};

export const searchWebsiteMetadataFromPdf = (
  pdf: Blob,
  url: string,
  onProgress?: (progress: number) => void,
): Promise<AxiosResponse<CitationDataLoose>> => {
  return axios.post(
    createScraperUrl(`/website-data/files/${encodeURIComponent(url)}`),
    pdf,
    {
      headers: { 'Content-Type': 'application/pdf' },
      onUploadProgress: ({ loaded, total }) => {
        onProgress?.(calculateProgress(loaded, total));
      },
    },
  );
};

export const searchJournalMetadataFromPdfUpload = (
  pdf: string,
): Promise<AxiosResponse<unknown>> => {
  return axios.post(createScraperUrl('/process-uploaded-pdf'), {
    pdf: pdf,
  });
};

export const getPopularCitationStyles = (): Promise<
  AxiosResponse<StylesResponse>
> => {
  return axios.get(createApiUrl(`/styles?filter=isPopular`));
};

export const getCitationStyles = (): Promise<AxiosResponse<StylesResponse>> => {
  return axios.get(createApiUrl(`/styles`));
};

export const getCitationStyleXML = (
  citationStyle: string,
): Promise<AxiosResponse<string>> => {
  return axios.get(
    createApiUrl(`/styles/${encodeURIComponent(citationStyle)}`),
  );
};
