import { callAppScript } from '..';
import { DocumentStoreState } from './types';

/* 
  !!! THIS IS DEPRECATED - IT IS USED ONLY FOR BACKWARD COMPATIBILITY !!!

  Google App Script provides PropertiesService api, which is a document-scoped key-value store.
  It is not exposed to the sidebar widgets' JS runtime though - in order to use it one needs
  to use asynchronous script api (window.google.script.run object), which is then handled by 
  App Script server running in the background.
  This file runs two functions through script api:
  - getDocumentProperties,
  - setDocumentProperties.
  Both of them use PropertiesService to read and write data to document store.
  Their implementation can be found in dist/code.js
  
  PropertiesService has following quotas per document:
  - 500KB total size,
  - 9KB value size,
  - 50000 read/writes.
  In order to comply with value size quota stringified state is split into smaller chunks,
  resulting in a following object being stored using PropertiesService:
  {
    state0: '...',
    state1: '...',
    ...,
    stateN: '...',
  }
  JSON.stringify uses UTF encoding, in which a character can take up to 4B. Therefore,
  each stateN value is at most 2304 characters long (9KB/4B = 2.25K = 2.25*1024 = 2304).

  Ref:
  - https://developers.google.com/apps-script/guides/services/quotas
  - https://stackoverflow.com/a/42878170
  - https://developers.google.com/apps-script/guides/properties
*/

type Properties = Record<string, string>;

const propertyKeyRegExp = /^state(\d+)$/;

const indexFromPropertyKey = (key: string): number =>
  Number(propertyKeyRegExp.exec(key)?.[1]);

const propertiesToState = (
  properties: Properties,
): DocumentStoreState | undefined => {
  const stringifiedState = Object.keys(properties)
    .filter((key) => propertyKeyRegExp.test(key))
    .sort((a, b) => {
      const aNumber = indexFromPropertyKey(a);
      const bNumber = indexFromPropertyKey(b);
      return aNumber - bNumber;
    })
    .reduce((acc, key) => `${acc}${properties[key]}`, '');

  if (!stringifiedState) {
    return undefined;
  }

  return JSON.parse(stringifiedState) as DocumentStoreState;
};

export const getStateFromDocumentProperties = async (): Promise<
  DocumentStoreState | undefined
> => {
  const properties: Properties = await callAppScript('getDocumentProperties');
  return propertiesToState(properties);
};
