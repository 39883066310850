import CitationTypes from 'types/citation-types';
import { HYDRATE } from 'next-redux-wrapper';
import * as CitationListModel from 'models/citation-list-model';
import * as CitationModel from 'models/citation-model';
import { CitationData, CitationFieldKey } from 'types/fields';
import citationForms, { getFieldMetadata } from 'types/citation-forms';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { isCitationEmpty } from 'lib/is-citation-empty';
import {
  generateMissingFieldLabel,
  initializeRequiredFieldsMetadata,
  reinitializeRequiredFieldsMetadata,
} from 'lib/missing-field-helpers';
import isCitationFieldValEmpty from 'lib/is-citation-field-empty';
import CitationFocusedField from 'types/citation-focused-field';
import { login, logout, setUserData, signup } from './auth-module';
import {
  addGdocCitationsToCurrentList,
  deleteCitationList,
  loadCitationList,
  newCitationList,
} from './citation-list-module';
import { CitationStyle } from 'types/citation-style';
import { DEFAULT_CITATION_STYLE } from 'lib/citation-defaults';
import * as CitationListActions from './citation-list-module';
import { setCSLCitationStyle } from 'lib/get-citations';
import { v4 as uuidv4 } from 'uuid';
import {
  CurrentCitationListInfo,
  ResponseCitationList,
} from 'types/citation-list';
import { NlpData } from 'types/nlp-data';
import { publishListChange } from 'lib/inter-tab-communication';
import { HydrateAction } from 'types/hydrate-action';
import CSLToCitationType from 'lib/csl-to-citation-type';

const prefix = 'citation';

export type Citation = {
  citationType: CitationTypes;
  data: CitationData;
  requiredFieldsMetadata: RequiredFieldsMetadata;
};

export interface RequiredFieldsMetadata {
  ignoredFields: LabelToKeySet; // 2 sets so we avoid having to loop through each required field
  missingFields: LabelToKeySet; //    in citation output
}

export type LabelToKeySet = { [key: string]: CitationFieldKey }; // maps name chosen for missing field labels to the original field key

export enum EditStates {
  NONE = 'none',
  BIB_ITEM = 'bib-item',
  IN_TEXT_FOOT = 'in-text-footer',
}

export type CitationStateWritable = {
  isManual: boolean;
  deleting: string[];
  editState: EditStates;
  glowingCitation: string;
  citationStyles: {
    title: string;
    titleShort?: string;
    priority: number;
  }[];
  citationStyle: CitationStyle;
  loadingCitationStyles: boolean;
  loadingBibliography: boolean;
  citations: Citation[];
  citationInputIndex: number;
  focusedCitationField?: CitationFocusedField;
  citationNlpData: NlpData;
  inputFormGlowFlag: boolean; // this val is just needed to re-render input form component
  wordLegacyCitations: Citation[];
  recentlyDeletedIndex: number;
  recentlyDeletedCitation: Citation;
  undoPossible: boolean;
  undoList: Citation[];
  editCount: number;
  existingSearchValue?: string;
};

export type CitationState = Readonly<CitationStateWritable>;

export const initialState: CitationState = {
  isManual: false,
  deleting: [],
  editState: EditStates.NONE,
  glowingCitation: '',
  citationStyle: { name: '', xml: '' },
  citationStyles: [],
  loadingCitationStyles: false,
  loadingBibliography: true,
  citations: [],
  citationInputIndex: 0,
  citationNlpData: {},
  inputFormGlowFlag: false,
  wordLegacyCitations: [],
  recentlyDeletedIndex: 0,
  recentlyDeletedCitation: {
    citationType: CitationTypes.artwork,
    data: { id: '' },
    requiredFieldsMetadata: { ignoredFields: {}, missingFields: {} },
  },
  undoPossible: false,
  undoList: [],
  editCount: -1,
};

export const getPopularCitationStyles = createAsyncThunk(
  `${prefix}/getPopularCitationStyles`,
  async () => {
    const response = await CitationModel.getPopularCitationStyles();
    return response.data;
  },
);

export const getCitationStyles = createAsyncThunk(
  `${prefix}/getCitationStyles`,
  async () => {
    const response = await CitationModel.getCitationStyles();
    return response.data;
  },
);

export const deleteCitation = createAsyncThunk(
  `${prefix}/deleteCitation`,
  async ({ listId, citationId }: { listId: string; citationId: string }) => {
    await CitationListModel.deleteCitation(listId, citationId);
    return { listId, citationId };
  },
);

export const addWordCitationsToCurrentList = createAsyncThunk(
  `${prefix}/addWordCitationsToCurrentList`,
  async (wordId: string) => {
    const response = await CitationListModel.addWordCitationsToCurrentList(
      wordId,
    );
    return response.data;
  },
);

export const loadWordLegacyCitations = createAsyncThunk(
  `${prefix}/loadWordLegacyCitations`,
  async () => {
    const res = await CitationListModel.getWordLegacyCitations();
    return res.data;
  },
);

export const citationSlice = createSlice({
  name: prefix,
  initialState: initialState,
  reducers: {
    addNlpData: (state, action) => {
      state.citationNlpData = { ...state.citationNlpData, ...action.payload };
    },

    startNewCitation: (state, action) => {
      const citationInputType = action.payload;
      const cslCitation = citationForms[citationInputType as CitationTypes];
      if (cslCitation) {
        const newCitation = {
          citationType: citationInputType,
          data: { id: uuidv4(), type: cslCitation.type },
          requiredFieldsMetadata:
            initializeRequiredFieldsMetadata(citationInputType),
        };
        state.editState = EditStates.NONE;
        state.glowingCitation = '';
        state.isManual = false;
        if (
          state.citationInputIndex === state.citations.length - 1 &&
          isCitationEmpty(state.citations[state.citationInputIndex])
        ) {
          state.citations[state.citationInputIndex] = newCitation;
        } else {
          state.citationInputIndex = state.citations.length;
          state.citations.push(newCitation);
        }
      } else {
        console.error('CSL Citation Type not found');
      }
    },
    addExtractedCitation: (state, action) => {
      state.editState = EditStates.NONE;
      state.isManual = false;
      state.citationInputIndex = state.citations.length;
      const citation = action.payload;
      const form = citationForms[citation.type as CitationTypes];
      state.citations.push({
        citationType: form && citation.type,
        data: { ...citation, id: uuidv4() },
        requiredFieldsMetadata: {
          ignoredFields: {},
          missingFields: {},
        },
      });
      state.editCount = state.editCount + 1;
    },
    addCitationsFromRouterQuery: (state, action) => {
      const citations = action.payload as Citation[];
      if (citations.length === 0) {
        return;
      }
      state.citations = state.citations.concat(
        citations.map((citation, idx) => {
          const formExists = !!citationForms[citation.citationType];
          return {
            ...citation,
            data: {
              ...citation.data,
              id: uuidv4() + idx,
            },
            citationType: formExists && citation.citationType,
          };
        }),
      );
      state.citationInputIndex = state.citations.length; // do not show any edit form. Following this pattern based on HYDRATE action below
      state.editCount = state.editCount + 1;
    },
    addCitationsFromDocumentStore: (state, action) => {
      const citations = action.payload as Citation[];
      if (citations.length === 0) {
        return;
      }
      state.citations = state.citations.concat(
        citations.map((citation) => {
          const formExists = !!citationForms[citation.citationType];
          return {
            ...citation,
            citationType: formExists && citation.citationType,
          };
        }),
      );
      state.citationInputIndex = state.citations.length; // do not show any edit form. Following this pattern based on HYDRATE action below
      state.editCount = state.editCount + 1;
    },
    addCitationsFromListImport: (
      state,
      action: { payload: CitationData[] },
    ) => {
      const citationData = action.payload;
      if (citationData.length === 0) return;
      state.citations = state.citations.concat(
        citationData.map((data) => {
          if (data.type === undefined) throw new Error('Missing citation type');
          const citationType = CSLToCitationType(data.type);
          const newCitation: Citation = {
            citationType: citationType,
            data,
            requiredFieldsMetadata:
              initializeRequiredFieldsMetadata(citationType),
          };
          const newRequiredFieldsMetadata = reinitializeRequiredFieldsMetadata(
            newCitation,
            citationType,
          );
          newCitation.requiredFieldsMetadata = newRequiredFieldsMetadata;
          return newCitation;
        }),
      );
      state.citationInputIndex = state.citations.length; // do not show any edit form. Following this pattern based on HYDRATE action below
      state.editCount = state.editCount + 1;
    },
    setListFromAnotherTab: (state, action) => {
      state.citations = action.payload.citations;
      state.citationStyle = action.payload.style;
      state.citationInputIndex = state.citations.length;
      state.editCount = state.editCount + 1;
    },
    ignoreCitationMissingField: (state, action) => {
      const field = action.payload.field;
      const citationId = action.payload.id;
      if (!field || !citationId) return;
      const citation = state.citations.find((citation) => {
        return citation.data.id === citationId;
      });
      if (!citation) return;

      const missingFields = citation.requiredFieldsMetadata.missingFields;
      if (missingFields[field]) {
        delete missingFields[field];
      }
      citation.requiredFieldsMetadata.ignoredFields[field] = field;
    },
    editCurrentCitation: (state, action) => {
      state.citations[state.citationInputIndex].data = {
        ...state.citations[state.citationInputIndex].data,
        ...action.payload,
      };

      const currentCitation = state.citations[state.citationInputIndex];

      const requiredFieldsDetails = currentCitation.requiredFieldsMetadata;

      const type =
        currentCitation.data.regularType || currentCitation.citationType;
      if (!type) return;
      const fieldDetails = getFieldMetadata(type);
      for (const key in action.payload) {
        const curKey = key as CitationFieldKey; // will always be a workng cast
        const fieldMetadata = fieldDetails[curKey];

        if (!fieldMetadata || !fieldMetadata.isRequired) continue;
        const name = generateMissingFieldLabel(fieldMetadata);
        if (requiredFieldsDetails.ignoredFields[name]) continue;

        if (isCitationFieldValEmpty(currentCitation.data[curKey])) {
          requiredFieldsDetails.missingFields[name] = curKey;
        } else if (requiredFieldsDetails.missingFields[name]) {
          delete requiredFieldsDetails.missingFields[name];
        }
      }
      state.editCount = state.editCount + 1;
    },
    setCitationType: (state, action) => {
      const type = action.payload;
      const currentCitation = state.citations[state.citationInputIndex];
      const newRequiredFieldsMetadata = reinitializeRequiredFieldsMetadata(
        currentCitation,
        type,
      );
      currentCitation.citationType = type;
      currentCitation.requiredFieldsMetadata = newRequiredFieldsMetadata;
    },
    // selectCitation and selectIntextFooter share similar logic - consider a refactor
    selectCitation: (state, action) => {
      const isPrevCitationEmpty = isCitationEmpty(
        state.citations[state.citationInputIndex],
      );
      if (isPrevCitationEmpty) {
        state.citations = state.citations.filter(
          (_, index) => index !== state.citationInputIndex,
        );
      }
      state.editState = EditStates.BIB_ITEM;
      const index = state.citations.findIndex((citation) => {
        return citation.data.id === action.payload;
      });
      if (state.focusedCitationField) {
        state.focusedCitationField.key = undefined; // reset focused field
      }
      if (index !== state.citationInputIndex) {
        state.citationInputIndex = index;
      }
    },
    selectIntextFooter: (state, action) => {
      const isPrevCitationEmpty = isCitationEmpty(
        state.citations[state.citationInputIndex],
      );
      if (isPrevCitationEmpty) {
        state.citations = state.citations.filter(
          (_, index) => index !== state.citationInputIndex,
        );
      }
      state.editState = EditStates.IN_TEXT_FOOT;
      state.glowingCitation = '';
      scrollTo({ top: 0 });
      const index = state.citations.findIndex((citation) => {
        return citation.data.id === action.payload;
      });
      if (state.focusedCitationField) {
        state.focusedCitationField.key = undefined; // reset focused field
      }
      if (index !== state.citationInputIndex) {
        state.citationInputIndex = index;
      }
    },
    deleteCurrentCitation: (state) => {
      state.citations = state.citations.filter(
        (_, index) => index !== state.citationInputIndex,
      );
      state.citationInputIndex = state.citations.length;
      state.editState = EditStates.NONE;
      state.isManual = false;
      state.editCount = state.editCount + 1;
    },
    addToUndoList: (state, citation) => {
      state.undoList.push(citation.payload);
    },
    removeFromUndoList: (state, citationId) => {
      state.undoList = state.undoList.filter((citation) => {
        return citation.data.id !== citationId.payload;
      });
    },
    undoDeleteCitation: (state, citation) => {
      state.undoList = state.undoList.filter((c) => {
        return c.data.id !== citation.payload.data.id;
      });
      // fixes issue where undoing a delete would cause the styling to be greyed out
      state.deleting = state.deleting.filter((str) => {
        return str !== citation.payload.data.id;
      });
      state.citations = [...state.citations, citation.payload];
      state.citationInputIndex = state.citations.length;
      state.editCount = state.editCount + 1;
    },
    finishEditingCitation: (state) => {
      const currentCitation = state.citations[state.citationInputIndex];
      if (currentCitation) {
        state.glowingCitation = currentCitation?.data?.id;
      }
      if (isCitationEmpty(state.citations[state.citationInputIndex])) {
        state.citations = state.citations.filter(
          (_, index) => index !== state.citationInputIndex,
        );
      }
      state.editState = EditStates.NONE;
      state.citationInputIndex = state.citations.length;
    },
    fillManually: (state) => {
      state.isManual = true;
      state.editState = EditStates.BIB_ITEM;
    },
    setLoadingBibliographyFalse: (state) => {
      state.loadingBibliography = false;
    },
    focusField: (state, action) => {
      // Used by CitationInputForm to focus an input field.
      // The timestamp is to help ensure focuses are considered only once
      //  and should be handled by clients that reference state.focusedCitationField.
      state.focusedCitationField = {
        key: action.payload,
        timeStamp: new Date().getTime(),
      };
    },
    triggerInputFormGlow: (state) => {
      state.inputFormGlowFlag = !state.inputFormGlowFlag;
    },
    moveCitation: (
      state,
      action: PayloadAction<{ fromIndex: number; toIndex: number }>,
    ) => {
      const { fromIndex, toIndex } = action.payload;
      const citation = state.citations[fromIndex];
      state.citations.splice(fromIndex, 1);
      state.citations.splice(toIndex, 0, citation);
      if (state.editState !== EditStates.NONE) {
        state.citationInputIndex = toIndex;
      }
      state.editCount = state.editCount + 1;
    },
    importWordLegacyCitations: (state) => {
      state.citations = state.citations.concat(state.wordLegacyCitations);
      state.citationInputIndex = state.citations.length; // do not show any edit form. Following this pattern based on HYDRATE action below
    },
    setExistingSearchValue: (state, action) => {
      state.existingSearchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Todo: improve types
    const resetState = (state: CitationStateWritable): void => {
      state.citations = [];
      state.citationInputIndex = 0;
      state.editState = EditStates.NONE;
      state.isManual = false;
      state.citationStyle = DEFAULT_CITATION_STYLE;
      state.citationNlpData = {};
      setCSLCitationStyle(state.citationStyle);
      state.editCount = state.editCount + 1;
    };
    const updateStateFromCurrentListInfo = (
      state: CitationStateWritable,
      currentCitations: CurrentCitationListInfo,
      computeCSL = true,
    ): void => {
      state.citations = currentCitations.citations || [];
      state.citationInputIndex = currentCitations.citations?.length || 0;
      state.citationStyle = currentCitations.style || DEFAULT_CITATION_STYLE;
      if (computeCSL) {
        setCSLCitationStyle(state.citationStyle);
      }
    };
    const updateCitationsFromBackend = (
      state: CitationStateWritable,
      action: PayloadAction<ResponseCitationList>,
    ): void => {
      state.editState = EditStates.NONE;
      state.isManual = false;
      if (action.payload.currentCitations) {
        updateStateFromCurrentListInfo(
          state,
          action.payload.currentCitations,
          false,
        );
      }
      state.editCount = state.editCount + 1;
    };
    const updateCitationsFromLoadedList = (
      state: CitationStateWritable,
      // TODO: improve payload type
      action: PayloadAction<any, string, unknown, never>,
    ): void => {
      updateStateFromCurrentListInfo(state, action.payload);
      state.editState = EditStates.NONE;
      state.isManual = false;
      state.editCount = state.editCount + 1;
    };

    builder.addCase(getPopularCitationStyles.pending, (state) => {
      state.loadingCitationStyles = true;
    });
    builder.addCase(getPopularCitationStyles.fulfilled, (state, action) => {
      state.loadingCitationStyles = false;
      state.citationStyles = action.payload.data;
    });
    builder.addCase(getCitationStyles.fulfilled, (state, action) => {
      state.citationStyles = action.payload.data;
    });
    builder.addCase(setUserData, updateCitationsFromBackend);
    builder.addCase(login.fulfilled, updateCitationsFromBackend);
    builder.addCase(signup.fulfilled, updateCitationsFromBackend);
    builder.addCase(deleteCitationList.fulfilled, updateCitationsFromBackend);
    builder.addCase(logout.fulfilled, resetState);
    builder.addCase(newCitationList.fulfilled, (state, action) => {
      updateStateFromCurrentListInfo(state, action.payload);
      state.editState = EditStates.NONE;
      state.isManual = false;
    });
    builder.addCase(loadCitationList.fulfilled, (state, action) => {
      updateStateFromCurrentListInfo(state, action.payload);
      state.editState = EditStates.NONE;
      state.isManual = false;
    });
    builder.addCase(
      addGdocCitationsToCurrentList.fulfilled,
      updateCitationsFromLoadedList,
    );
    builder.addCase(
      addWordCitationsToCurrentList.fulfilled,
      updateCitationsFromLoadedList,
    );

    builder.addCase(CitationListActions.setCitationStyle, (state, action) => {
      state.citationStyle = action.payload;
      state.editCount = state.editCount + 1;
    });
    builder.addCase(deleteCitation.pending, (state, action) => {
      const citationToDeleteIndex = state.citations.findIndex((citation) => {
        return citation.data.id === action.meta.arg.citationId;
      });
      state.deleting.push(state.citations[citationToDeleteIndex].data.id);
    });
    builder.addCase(deleteCitation.fulfilled, (state, action) => {
      const citationToDeleteIndex = state.citations.findIndex((citation) => {
        return citation.data.id === action.payload.citationId;
      });
      state.citations = state.citations.filter(
        (_, index) => index !== citationToDeleteIndex,
      );
      state.deleting = state.deleting.splice(citationToDeleteIndex, 1);
      if (state.citationInputIndex === citationToDeleteIndex) {
        state.citationInputIndex = state.citations.length;
        state.editState = EditStates.NONE;
        state.isManual = false;
      } else if (state.citationInputIndex === state.citations.length) {
        state.citationInputIndex = state.citationInputIndex - 1;
      }
      state.editCount = state.editCount + 1;
      publishListChange({
        id: action.payload.listId,
        citations: state.citations,
        style: state.citationStyle,
      });
    });
    builder.addCase(
      CitationListActions.loadWordCitationList.fulfilled,
      (state, action) => {
        const citations = action.payload.citations;
        if (citations.length === 0) {
          return;
        }
        state.citations = state.citations.concat(
          citations.map((citation) => {
            const formExists = !!citationForms[citation.citationType];
            return {
              ...citation,
              citationType: formExists && citation.citationType,
            };
          }),
        );
        state.citationInputIndex = state.citations.length; // do not show any edit form. Following this pattern based on HYDRATE action below
        state.citationStyle = action.payload.style;
      },
    );
    builder.addCase(loadWordLegacyCitations.fulfilled, (state, action) => {
      state.wordLegacyCitations = action.payload;
    });
    builder.addCase(HYDRATE, (state, action: HydrateAction) => {
      const payloadCitation = action.payload.citation;
      state.citations = payloadCitation.citations;
      state.citationInputIndex = state.citations.length;
      state.citationStyle = payloadCitation.citationStyle.name
        ? payloadCitation.citationStyle
        : DEFAULT_CITATION_STYLE;
    });
  },
});

export const {
  addNlpData,
  startNewCitation,
  addExtractedCitation,
  editCurrentCitation,
  setCitationType,
  selectCitation,
  selectIntextFooter,
  setLoadingBibliographyFalse,
  deleteCurrentCitation,
  undoDeleteCitation,
  addToUndoList,
  removeFromUndoList,
  finishEditingCitation,
  fillManually,
  ignoreCitationMissingField,
  focusField,
  addCitationsFromRouterQuery,
  addCitationsFromDocumentStore,
  addCitationsFromListImport,
  triggerInputFormGlow,
  moveCitation,
  setListFromAnotherTab,
  importWordLegacyCitations,
  setExistingSearchValue,
} = citationSlice.actions;

export default citationSlice.reducer;
