import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as AuthModel from 'models/auth-model';
import { Me } from 'models/auth-model';
import { HYDRATE } from 'next-redux-wrapper';
import axios from 'axios';
import { HydrateAction } from 'types/hydrate-action';

type Status = 'to be determined' | 'present' | 'not present';

export type AuthState = Readonly<{
  status: Status;
  data?: Me;
  originalRequestId?: string;
  gdocsExport: boolean;
}>;

export const initialState: AuthState = {
  status: 'to be determined',
  gdocsExport: false,
};

const prefix = 'auth';

export const logout = createAsyncThunk(`${prefix}/logout`, async () => {
  const response = await AuthModel.logout();
  return response.data;
});

export const login = createAsyncThunk(
  `${prefix}/login`,
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await AuthModel.login(email, password);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
      // Should still reject in future cases
      return rejectWithValue(undefined);
    }
  },
);

export const signup = createAsyncThunk(
  `${prefix}/signup`,
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await AuthModel.signup(email, password);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
      // Should still reject in future cases
      return rejectWithValue(undefined);
    }
  },
);

export const resetPassword = createAsyncThunk(
  `${prefix}/resetPassword`,
  async (
    { token, password }: { token: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await AuthModel.resetPassword(token, password);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response?.data);
      }
      // Should still reject in future cases
      return rejectWithValue(undefined);
    }
  },
);

export const clearSession = createAction('auth/clearSession');

export const setGdocsExport = createAsyncThunk(
  `${prefix}/setGdocsExport`,
  async (gdocsExport: boolean) => {
    await AuthModel.setGdocsExport(gdocsExport);
    return gdocsExport;
  },
);

export const authSlice = createSlice({
  name: prefix,
  initialState: initialState,
  reducers: {
    clearSession: (state) => {
      state.data = undefined;
      state.status = 'not present';
      state.gdocsExport = false;
    },
    setOriginalRequestId: (state, action) => {
      state.originalRequestId = action.payload;
    },
    setUserData: (state, action) => {
      const isLoggedIn = Boolean(action.payload.email);
      state.gdocsExport = action.payload.gdocsExport;
      if (isLoggedIn) {
        state.status = 'present';
        state.data = action.payload;
      } else {
        state.status = 'not present'; // able to retrieve prev session data, but no user/account
      }
    },
    setUserNotPresent: (state) => {
      state.status = 'not present';
      state.gdocsExport = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.status = 'not present';
      state.data = undefined;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.status = 'present';
      state.data = action.payload;
      state.gdocsExport = action.payload.gdocsExport;
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.status = 'present';
      state.data = action.payload;
      state.gdocsExport = action.payload.gdocsExport;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.status = 'present';
      state.data = action.payload;
      state.gdocsExport = action.payload.gdocsExport;
    });
    builder.addCase(setGdocsExport.fulfilled, (state, action) => {
      state.gdocsExport = action.payload;
    });
    builder.addCase(HYDRATE, (state, action: HydrateAction) => {
      state.status = action.payload.auth.status;
      state.data = action.payload.auth.data;
      state.gdocsExport = action.payload.auth.gdocsExport;
      state.originalRequestId = action.payload.auth.originalRequestId;
    });
  },
});

export default authSlice.reducer;
export const { setOriginalRequestId, setUserData, setUserNotPresent } =
  authSlice.actions;
