import FormField from './form-field';
import CSLCitationTypes from './csl-citation-types';
import { colors } from 'css/variables';
import BookIcon from 'static/svgs/citation-type-icons/book';
import NewspaperIcon from 'static/svgs/citation-type-icons/newspaper';
import CanvasIcon from 'static/svgs/citation-type-icons/canvas';
import PaperIcon from 'static/svgs/citation-type-icons/paper';
import WebsiteIcon from 'static/svgs/citation-type-icons/website';
import CitationTypes from './citation-types';
import {
  AnnoteInformation,
  BookModalInformation,
  JournalArticleModalInformation,
  WebsiteModalInformation,
} from '../components/app/modal-information';
export const getFieldMetadata = (type: CitationTypes): FormFieldGroup => {
  const formDetails = citationForms[type];

  if (!formDetails) return {};
  return formDetails.fields;
};

export interface CitationForm {
  name: string;
  color: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  type: CSLCitationTypes;
  isPrioritized?: boolean;
  fields: FormFieldGroup;
}

export type FormFieldGroup = {
  [key: string]: FormField;
};

const citationForms: Record<CitationTypes, CitationForm> = {
  artwork: {
    name: 'Artwork',
    color: colors.blue.medium,
    icon: CanvasIcon,
    type: CSLCitationTypes['graphic'],
    fields: {
      author: {
        type: 'name',
        label: 'Artist',
        isImportant: true,
        isRequired: true,
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of Artwork',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      medium: {
        type: 'text',
        label: 'Medium',
        isImportant: true,
        textCase: 'title',
      },
      archive: {
        type: 'text',
        label: 'Museum or Gallery',
        textCase: 'title',
      },
      archive_location: {
        type: 'text',
        label: 'Museum or Gallery location',
      },
      URL: {
        type: 'text',
        label: 'URL',
        isImportant: true,
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        isImportant: true,
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  blogPost: {
    name: 'Blog Post',
    color: colors.teal.medium,
    icon: PaperIcon,
    type: CSLCitationTypes['post-weblog'],
    fields: {
      author: {
        type: 'name',
        label: 'Post author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of post',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'container-title': {
        type: 'text',
        label: 'Blog title',
        isImportant: true,
        textCase: 'title',
      },
      URL: {
        type: 'text',
        label: 'URL',
        isImportant: true,
        isRequired: true,
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        isImportant: true,
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  book: {
    name: 'Book',
    color: colors.purple.medium,
    type: CSLCitationTypes['book'],
    icon: BookIcon,
    isPrioritized: true,
    fields: {
      author: {
        type: 'name',
        label: 'Book author',
        isImportant: true,
        isRequired: true,
        modalInformation: BookModalInformation['contributors'],
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      translator: {
        type: 'name',
        label: 'Translator',
      },
      issued: {
        type: 'year',
        label: 'Year published',
        isImportant: true,
        isRequired: true,
        modalInformation: BookModalInformation['issued'],
      },
      'original-date': {
        type: 'date',
        label: 'Date originally published',
        modalInformation: BookModalInformation['original-date'],
      },
      title: {
        type: 'text',
        label: 'Title of book',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      edition: {
        type: 'text',
        label: 'Edition',
        isImportant: true,
        modalInformation: BookModalInformation['edition'],
      },
      volume: {
        type: 'text',
        label: 'Volume number',
        modalInformation: BookModalInformation['volume'],
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
        modalInformation: BookModalInformation['publisher'],
      },
      'publisher-place': {
        type: 'text',
        label: 'Publisher city',
        isImportant: true,
        modalInformation: BookModalInformation['publisher-place'],
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
        modalInformation: BookModalInformation['page'],
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      ISBN: {
        type: 'text',
        label: 'ISBN',
        modalInformation: BookModalInformation['ISBN'],
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
        modalInformation: AnnoteInformation,
      },
    },
  },
  bookReview: {
    name: 'Book Review',
    color: colors.navy.medium,
    icon: PaperIcon,
    type: CSLCitationTypes['review-book'],
    fields: {
      author: {
        type: 'name',
        label: 'Review author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Review editor',
      },
      title: {
        type: 'text',
        label: 'Title of review',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      'reviewed-author': {
        type: 'name',
        label: 'Author of original book',
        isImportant: true,
        isRequired: true,
      },
      issued: {
        type: 'date',
        label: 'Date original book published',
        isImportant: true,
        isRequired: true,
      },
      'reviewed-title': {
        type: 'text',
        label: 'Title of original book',
        isRequired: true,
        isImportant: true,
        textCase: 'title',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },

  bookChapter: {
    name: 'Book Chapter',
    color: colors.green.medium,
    icon: BookIcon,
    type: CSLCitationTypes['chapter'],
    fields: {
      author: {
        type: 'name',
        label: 'Chapter author',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of chapter',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'original-author': {
        type: 'name',
        label: 'Book author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Book editor',
      },
      translator: {
        type: 'name',
        label: 'Translator',
      },
      issued: {
        type: 'year',
        label: 'Year published',
        isImportant: true,
        isRequired: true,
      },
      'container-title': {
        type: 'text',
        label: 'Title of book',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Publisher place',
        isImportant: true,
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  conferencePaper: {
    name: 'Conference Paper',
    color: colors.blue.medium,
    icon: BookIcon,
    type: CSLCitationTypes['paper-conference'],
    fields: {
      author: {
        type: 'name',
        label: 'Paper author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of paper',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'container-title': {
        type: 'text',
        label: 'Name of journal/proceedings',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        isImportant: true,
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Publisher place',
      },
      volume: {
        type: 'text',
        label: 'Volume number',
      },
      issue: {
        type: 'text',
        label: 'Issue number',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
      },
      event: {
        type: 'text',
        label: 'Name of event',
        textCase: 'title',
      },
      'event-place': {
        type: 'text',
        label: 'Location of event',
      },
      'event-date': {
        type: 'date',
        label: 'Date of event',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  database: {
    name: 'Database',
    color: colors.teal.medium,
    icon: BookIcon,
    type: CSLCitationTypes['article-journal'],
    fields: {
      author: {
        type: 'name',
        label: 'Article author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of article',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'container-title': {
        type: 'text',
        label: 'Publication title',
        isImportant: true,
        textCase: 'title',
      },
      volume: {
        type: 'text',
        label: 'Volume number',
      },
      issue: {
        type: 'text',
        label: 'Issue number',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
      },
      archive: {
        type: 'text',
        label: 'Database name',
        isImportant: true,
        isRequired: true,
      },
      URL: {
        type: 'text',
        label: 'URL or search text',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  dictionaryEntry: {
    name: 'Dictionary Entry',
    color: colors.purple.medium,
    icon: BookIcon,
    type: CSLCitationTypes['entry-dictionary'],
    fields: {
      author: {
        type: 'name',
        label: 'Entry author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of entry',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'container-title': {
        type: 'text',
        label: 'Name of dictionary',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Publisher place',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  ebook: {
    name: 'E-book',
    color: colors.red.medium,
    icon: BookIcon,
    type: CSLCitationTypes['book'],
    fields: {
      author: {
        type: 'name',
        label: 'Author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      translator: {
        type: 'name',
        label: 'Translator',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of e-book',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Publisher place',
      },
      edition: {
        type: 'text',
        label: 'Edition',
      },
      volume: {
        type: 'text',
        label: 'Volume number',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      URL: {
        type: 'text',
        label: 'URL',
        isImportant: true,
        isRequired: true,
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        isImportant: true,
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  encyclopediaEntry: {
    name: 'Encyclopedia Entry',
    color: colors.blue.medium,
    icon: BookIcon,
    type: CSLCitationTypes['entry-encyclopedia'],
    fields: {
      author: {
        type: 'name',
        label: 'Entry author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      translator: {
        type: 'name',
        label: 'Translator',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of entry',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'container-title': {
        type: 'text',
        label: 'Name of encyclopedia',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      edition: {
        type: 'text',
        label: 'Edition',
      },
      volume: {
        type: 'text',
        label: 'Volume number',
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Publisher place',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      archive: {
        type: 'text',
        label: 'Database name',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  filmOrMovie: {
    name: 'Film/Movie (DVD, etc.)',
    color: colors.yellow.medium,
    icon: BookIcon,
    type: CSLCitationTypes['motion_picture'],
    fields: {
      director: {
        type: 'name',
        label: 'Director',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of film/movie',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      medium: {
        type: 'text',
        label: 'Medium',
        isImportant: true,
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Distributor',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Distributor location',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  image: {
    name: 'Image',
    color: colors.orange.medium,
    icon: BookIcon,
    type: CSLCitationTypes['graphic'],
    fields: {
      author: {
        type: 'name',
        label: 'Illustrator or copyright holder',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date of image',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title or description',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  interview: {
    name: 'Interview',
    color: colors.yellow.medium,
    icon: BookIcon,
    type: CSLCitationTypes['interview'],
    fields: {
      author: {
        type: 'name',
        label: 'Interview with',
        isImportant: true,
        isRequired: true,
      },
      interviewer: {
        type: 'name',
        label: 'Interviewer',
        isImportant: true,
      },
      issued: {
        type: 'date',
        label: 'Date of interview',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of interview',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'container-title': {
        type: 'text',
        label: 'Publication or container',
        textCase: 'title',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  journalArticle: {
    name: 'Journal Article',
    color: colors.orange.medium,
    type: CSLCitationTypes['article-journal'],
    icon: NewspaperIcon,
    isPrioritized: true,
    fields: {
      author: {
        type: 'name',
        label: 'Article author',
        isImportant: true,
        isRequired: true,
        modalInformation: JournalArticleModalInformation['contributors'],
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
        modalInformation: JournalArticleModalInformation['issued'],
      },
      title: {
        type: 'text',
        label: 'Title of article',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
        modalInformation: JournalArticleModalInformation['title'],
      },
      'container-title': {
        type: 'text',
        label: 'Name of journal',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
        modalInformation: JournalArticleModalInformation['container-title'],
      },
      volume: {
        type: 'text',
        label: 'Volume number',
        isImportant: true,
        isRequired: true,
        modalInformation: JournalArticleModalInformation['volume'],
      },
      issue: {
        type: 'text',
        label: 'Issue number',
        isImportant: true,
        isRequired: true,
        modalInformation: JournalArticleModalInformation['issue'],
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
        modalInformation: JournalArticleModalInformation['page'],
      },
      archive: {
        type: 'text',
        label: 'Database name',
        modalInformation: JournalArticleModalInformation['archive'],
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        modalInformation: JournalArticleModalInformation['accessed'],
      },
      DOI: {
        type: 'text',
        label: 'DOI',
        isImportant: true,
        modalInformation: JournalArticleModalInformation['DOI'],
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
        modalInformation: AnnoteInformation,
      },
    },
  },
  legalCase: {
    name: 'Legal Case',
    color: colors.red.medium,
    icon: BookIcon,
    type: CSLCitationTypes['legal_case'],
    fields: {
      author: {
        type: 'name',
        label: 'Case author',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Case name',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      issued: {
        type: 'date',
        label: 'Date of decision',
        isImportant: true,
        isRequired: true,
      },
      authority: {
        type: 'text',
        label: 'Issuing/judicial authority',
        textCase: 'title',
      },
      section: {
        type: 'text',
        label: 'Section of law report',
      },
      volume: {
        type: 'text',
        label: 'Volume number',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  legalBill: {
    name: 'Legal Bill',
    color: colors.green.medium,
    icon: BookIcon,
    type: CSLCitationTypes['bill'],
    fields: {
      author: {
        type: 'name',
        label: 'Sponsor',
        isImportant: true,
        isRequired: true,
      },
      issued: {
        type: 'date',
        label: 'Date issued',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of bill',
        isImportant: true,
        isRequired: true,
      },
      authority: {
        type: 'text',
        label: 'House the bill was heard in',
      },
      dimensions: {
        type: 'text',
        label: 'Session',
      },
      number: {
        type: 'text',
        label: 'Bill number',
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
      },
      'publisher-place': {
        // double-check this later
        type: 'text',
        label: 'Place of publication',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  legislation: {
    name: 'Legislation',
    color: colors.turquoise.medium,
    icon: BookIcon,
    type: CSLCitationTypes['legislation'],
    fields: {
      author: {
        type: 'name',
        label: 'Legislation author',
        isImportant: true,
      },
      title: {
        type: 'text',
        label: 'Title of legislation',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      volume: {
        type: 'text',
        label: 'Section number',
      },
      issue: {
        type: 'text',
        label: 'Reprint number',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  magazineArticle: {
    name: 'Magazine Article',
    color: colors.purple.medium,
    icon: BookIcon,
    type: CSLCitationTypes['article-magazine'],
    fields: {
      author: {
        type: 'name',
        label: 'Article author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of article',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'container-title': {
        type: 'text',
        label: 'Name of magazine',
        isImportant: true,
        isRequired: true,
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      archive: {
        type: 'text',
        label: 'Database name',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  map: {
    name: 'Map',
    color: colors.blue.medium,
    icon: BookIcon,
    type: CSLCitationTypes['map'],
    fields: {
      author: {
        type: 'name',
        label: 'Map author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of map',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'collection-title': {
        type: 'text',
        label: 'Collection title',
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Publisher place',
      },
      number: {
        type: 'text',
        label: 'Item number',
      },
      scale: {
        type: 'text',
        label: 'Scale of map',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  newsArticle: {
    name: 'News Article',
    color: colors.orange.medium,
    icon: BookIcon,
    type: CSLCitationTypes['article-newspaper'],
    fields: {
      author: {
        type: 'name',
        label: 'Article author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of article',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'container-title': {
        type: 'text',
        label: 'Name of news source',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      URL: {
        type: 'text',
        label: 'URL',
        isImportant: true,
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        isImportant: true,
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  patent: {
    name: 'Patent',
    color: colors.navy.medium,
    icon: BookIcon,
    type: CSLCitationTypes['patent'],
    fields: {
      author: {
        type: 'name',
        label: 'Inventor',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date issued',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of patent',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      issue: {
        type: 'text',
        label: 'Patent Number',
      },
      authority: {
        type: 'text',
        label: 'Issuing authority',
        textCase: 'title',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  personalCommunication: {
    name: 'Personal Communication',
    color: colors.yellow.medium,
    icon: BookIcon,
    type: CSLCitationTypes['personal_communication'],
    fields: {
      author: {
        type: 'name',
        label: 'Sender / Speaker / Author',
        isImportant: true,
        isRequired: true,
      },
      recipient: {
        type: 'name',
        label: 'Recipient',
      },
      issued: {
        type: 'date',
        label: 'Date of communication',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of communication',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      medium: {
        type: 'text',
        label: 'Medium',
        textCase: 'title',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  report: {
    name: 'Report',
    color: colors.teal.medium,
    icon: BookIcon,
    type: CSLCitationTypes['report'],
    fields: {
      author: {
        type: 'name',
        label: 'Report author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of report',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        isImportant: true,
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Publisher place',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      'container-title': {
        type: 'text',
        label: 'Website name',
        isImportant: true,
        textCase: 'title',
      },
      URL: {
        type: 'text',
        label: 'URL',
        isImportant: true,
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        isImportant: true,
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  review: {
    name: 'Review',
    color: colors.red.medium,
    icon: BookIcon,
    type: CSLCitationTypes['review'],
    fields: {
      author: {
        type: 'name',
        label: 'Review author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      'reviewed-author': {
        type: 'name',
        label: 'Author of reviewed item',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of review',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      'container-title': {
        type: 'text',
        label: 'Name of container',
        textCase: 'title',
      },
      volume: {
        type: 'text',
        label: 'Volume number',
      },
      issue: {
        type: 'text',
        label: 'Issue number',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  song: {
    name: 'Song',
    color: colors.green.medium,
    icon: BookIcon,
    type: CSLCitationTypes['song'],
    fields: {
      author: {
        type: 'name',
        label: 'Artist',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date produced',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of song',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      medium: {
        type: 'text',
        label: 'Medium',
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Publisher/producer',
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Publisher place',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  speech: {
    name: 'Speech',
    color: colors.teal.medium,
    icon: BookIcon,
    type: CSLCitationTypes['speech'],
    fields: {
      author: {
        type: 'name',
        label: 'Speaker',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of speech',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      medium: {
        type: 'text',
        label: 'Medium',
        textCase: 'title',
      },
      event: {
        type: 'text',
        label: 'Name of event',
        textCase: 'title',
      },
      'publisher-place': {
        type: 'text',
        label: 'Event place',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  thesis: {
    name: 'Thesis / dissertation',
    color: colors.purple.medium,
    icon: BookIcon,
    type: CSLCitationTypes['thesis'],
    fields: {
      author: {
        type: 'name',
        label: 'Author',
        isImportant: true,
        isRequired: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of thesis (or dissertation)',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      genre: {
        type: 'text',
        label: 'Document type',
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Academic publisher or institution name',
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  tvRadioBroadcast: {
    name: 'TV / Radio Broadcast',
    color: colors.turquoise.medium,
    icon: BookIcon,
    type: CSLCitationTypes['broadcast'],
    fields: {
      author: {
        type: 'name',
        label: 'Director or presenter',
        isImportant: true,
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date of broadcast',
        isImportant: true,
        isRequired: true,
      },
      title: {
        type: 'text',
        label: 'Title of broadcast or programme',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      publisher: {
        type: 'text',
        label: 'Publisher, broadcaster, or network',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      URL: {
        type: 'text',
        label: 'URL',
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  video: {
    name: 'Video',
    color: colors.orange.medium,
    icon: WebsiteIcon,
    type: CSLCitationTypes['video'],
    fields: {
      author: {
        type: 'name',
        label: 'Video author',
        isImportant: true,
        isRequired: true,
      },

      editor: {
        type: 'name',
        label: 'Editor',
      },

      title: {
        type: 'text',
        label: 'Title of video',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        isImportant: true,
        isRequired: true,
      },

      'container-title': {
        type: 'text',
        label: 'Publisher',
        isImportant: true,
        textCase: 'title',
      },

      medium: {
        type: 'text',
        label: 'Format',
        isImportant: true,
      },

      URL: {
        type: 'text',
        label: 'URL',
        isImportant: true,
      },

      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        isImportant: true,
      },

      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
      },
    },
  },
  website: {
    name: 'Website',
    color: colors.yellow.medium,
    icon: WebsiteIcon,
    type: CSLCitationTypes['webpage'],
    isPrioritized: true,
    fields: {
      author: {
        type: 'name',
        label: 'Webpage author',
        missingFieldLabel: 'Author',
        isImportant: true,
        isRequired: true,
        modalInformation: WebsiteModalInformation['contributors'],
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issued: {
        type: 'date',
        label: 'Date published',
        missingFieldLabel: 'Date',
        isImportant: true,
        isRequired: true,
        modalInformation: WebsiteModalInformation['issued'],
      },
      title: {
        type: 'text',
        label: 'Title of article or page',
        missingFieldLabel: 'Title',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
        modalInformation: WebsiteModalInformation['title'],
      },
      'container-title': {
        type: 'text',
        label: 'Website name',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
        modalInformation: WebsiteModalInformation['container-title'],
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        textCase: 'title',
        isImportant: true,
        modalInformation: WebsiteModalInformation['publisher'],
      },
      URL: {
        type: 'text',
        label: 'URL',
        isImportant: true,
        isRequired: true,
        modalInformation: WebsiteModalInformation['URL'],
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        isImportant: true,
        modalInformation: WebsiteModalInformation['accessed'],
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
        modalInformation: AnnoteInformation,
      },
    },
  },
  document: {
    name: 'Document',
    color: colors.yellow.medium,
    icon: PaperIcon,
    type: CSLCitationTypes['document'],
    fields: {
      author: {
        type: 'name',
        label: 'Document author',
        missingFieldLabel: 'Author',
        isImportant: true,
        modalInformation: WebsiteModalInformation['contributors'],
      },
      'container-title': {
        type: 'text',
        label: 'Collection title',
        isImportant: true,
        textCase: 'title',
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issue: {
        type: 'text',
        label: 'Issue number',
        isImportant: true,
        modalInformation: JournalArticleModalInformation['issue'],
      },
      issued: {
        type: 'date',
        label: 'Date published',
        missingFieldLabel: 'Date',
        isImportant: true,
        modalInformation: WebsiteModalInformation['issued'],
      },
      title: {
        type: 'text',
        label: 'Title of article or page',
        missingFieldLabel: 'Title',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
        modalInformation: WebsiteModalInformation['title'],
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        textCase: 'title',
        isImportant: true,
        modalInformation: WebsiteModalInformation['publisher'],
      },
      URL: {
        type: 'text',
        label: 'URL',
        isImportant: true,
        modalInformation: WebsiteModalInformation['URL'],
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        isImportant: true,
        modalInformation: WebsiteModalInformation['accessed'],
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
        modalInformation: AnnoteInformation,
      },
    },
  },
  manuscript: {
    name: 'Manuscript',
    color: colors.orange.medium,
    icon: PaperIcon,
    type: CSLCitationTypes['manuscript'],
    fields: {
      author: {
        type: 'name',
        label: 'Manuscript author',
        missingFieldLabel: 'Author',
        isImportant: true,
        isRequired: true,
        modalInformation: WebsiteModalInformation['contributors'],
      },
      'container-title': {
        type: 'text',
        label: 'Collection title',
        isImportant: true,
        textCase: 'title',
      },
      editor: {
        type: 'name',
        label: 'Editor',
      },
      issue: {
        type: 'text',
        label: 'Issue number',
        isImportant: true,
        modalInformation: JournalArticleModalInformation['issue'],
      },
      issued: {
        type: 'date',
        label: 'Date published',
        missingFieldLabel: 'Date',
        isImportant: true,
        isRequired: true,
        modalInformation: WebsiteModalInformation['issued'],
      },
      title: {
        type: 'text',
        label: 'Title of article or page',
        missingFieldLabel: 'Title',
        isImportant: true,
        isRequired: true,
        textCase: 'title',
        modalInformation: WebsiteModalInformation['title'],
      },
      page: {
        type: 'page-range',
        label: 'Page range',
        isImportant: true,
      },
      publisher: {
        type: 'text',
        label: 'Publisher',
        textCase: 'title',
        isImportant: true,
        modalInformation: WebsiteModalInformation['publisher'],
      },
      URL: {
        type: 'text',
        label: 'URL',
        isImportant: true,
        modalInformation: WebsiteModalInformation['URL'],
      },
      accessed: {
        type: 'date',
        label: 'Date accessed/viewed',
        isImportant: true,
        modalInformation: WebsiteModalInformation['accessed'],
      },
      annote: {
        type: 'textarea',
        label: 'Annotation',
        isImportant: true,
        modalInformation: AnnoteInformation,
      },
    },
  },
};

export default citationForms;
