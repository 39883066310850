/**
 * Enum of the different types of CSL citations.
 * Reference: https://github.com/citation-style-language/schema/blob/master/schemas/styles/csl-types.rnc
 */
enum CSLCitationTypes {
  'article' = 'article',
  'article-journal' = 'article-journal',
  'article-magazine' = 'article-magazine',
  'article-newspaper' = 'article-newspaper',
  'bill' = 'bill',
  'book' = 'book',
  'broadcast' = 'broadcast',
  'chapter' = 'chapter',
  'dataset' = 'dataset',
  'document' = 'document',
  'entry' = 'entry',
  'entry-dictionary' = 'entry-dictionary',
  'entry-encyclopedia' = 'entry-encyclopedia',
  'figure' = 'figure',
  'graphic' = 'graphic',
  'interview' = 'interview',
  'legal_case' = 'legal_case',
  'legislation' = 'legislation',
  'manuscript' = 'manuscript',
  'map' = 'map',
  'motion_picture' = 'motion_picture',
  'musical_score' = 'musical_score',
  'pamphlet' = 'pamphlet',
  'paper-conference' = 'paper-conference',
  'patent' = 'patent',
  'personal_communication' = 'personal_communication',
  'post' = 'post',
  'post-weblog' = 'post-weblog',
  'report' = 'report',
  'review' = 'review',
  'review-book' = 'review-book',
  'song' = 'song',
  'speech' = 'speech',
  'thesis' = 'thesis',
  'treaty' = 'treaty',
  'video' = 'video',
  'webpage' = 'webpage',

  // CSL types that are part of the official schema but that we don't use
  // 'classic' = 'classic',
  // 'collection' = 'collection',
  // 'performance' = 'performance',
  // 'periodical' = 'periodical',
  // 'regulation' = 'regulation',
  // 'event' = 'event',
  // 'hearing' = 'hearing',
  // 'software' = 'software',
  // 'standard' = 'standard',
}

export default CSLCitationTypes;
