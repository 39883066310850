import * as Sentry from '@sentry/node';
import { NextApiHandler } from 'next';
import Tracking from './tracking';
import { Contexts, CaptureContext } from '@sentry/types';
import { Scope } from '@sentry/node';

const shouldReportToSentry = !!process.env.SENTRY_DSN;

if (shouldReportToSentry) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENV,
    normalizeDepth: 30,
  });
}

export const reportException = (
  message: string | Error | unknown,
  props?: CaptureContext,
) => {
  console.error(message, props);
  const sentryId = Sentry.captureException(message, props);
  Tracking.trackEvent('Exception Report', {
    message: typeof message === 'string' ? message : 'No Exception Message',
    ...props,
    sentryId,
  });
};

export default reportException;

// TODO use this in auth endpoint?
export const reportGeneralException = (
  msg: string | Error | unknown,
  contextData: Record<any, any>,
): void => {
  reportException(msg, {
    contexts: {
      Context: {
        ...contextData,
      },
    },
  });
};

export const reportPdfException = (url: string, error: Error): void => {
  const hostname = new URL(url).hostname;
  const errorMsg = error.toString().substring(7);
  const msg = `PDF parsing error: ${errorMsg}`;
  reportException(msg, {
    tags: {
      hostname: hostname,
      url: url,
    },
    contexts: {
      Context: {
        url: url,
        error: errorMsg,
      },
    },
    fingerprint: [errorMsg],
  });
};

export const reportUrlException = (url: string, resStatus: number): void => {
  const hostname = new URL(url).hostname;
  const msg = `${resStatus} error from user-entered URL`;
  const status = String(resStatus);
  reportException(msg, {
    tags: {
      status: status,
      hostname: hostname,
      url: url,
    },
    contexts: {
      Context: {
        url: url,
      },
    },
    fingerprint: [status],
  });
};

export const reportUrlExceptionMisc = (
  url: string,
  error: string | any,
): void => {
  let hostname;
  try {
    hostname = new URL(url).hostname;
  } catch (err) {
    return; // don't want to send invalid url errors to sentry
  }
  let errorString;
  if (typeof error === 'string') {
    errorString = error;
  } else {
    errorString = error.code ? error.code : error.toString();
  }
  const msg = `${errorString} (from user-entered URL)`;
  reportException(msg, {
    tags: {
      error: errorString,
      hostname: hostname,
      url: url,
    },
    contexts: {
      Context: {
        url: url,
      },
    },
    fingerprint: [errorString],
  });
};

export const withExceptionReporting =
  (handler: NextApiHandler): NextApiHandler =>
  async (req, res) => {
    try {
      await handler(req, res);
    } catch (error) {
      reportException(error);
      res.status(500).end();
    }
  };

export const flush = (_time: number): Promise<boolean> | void => {
  if (typeof Sentry.flush !== 'undefined') {
    return Sentry.flush(_time);
  } else {
    return console.log('Flushing with time', _time);
  }
};

export const configureScope = (theFunction: (scope: Scope) => void): void => {
  return Sentry.configureScope(theFunction);
};
