import { DEFAULT_STYLE_KEY } from 'lib/citation-defaults';
import { DocumentStoreState } from './types';
import axios from 'axios';
import createApiUrl from 'lib/create-api-url';
import { getStateFromDocumentProperties } from './deprecated';
import { runningInGdocs } from '../index';

type RequestData = { gdocId: string; token: string };
export const getRequestData = (): RequestData => {
  if (!runningInGdocs()) {
    return {
      gdocId: 'local',
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2MjAzMDg4OTEsImdkb2NJZCI6ImxvY2FsIn0.SQNAclk8QqKtjufy7_ivsdeqUSId-bR6hpc26CIQAbg', // assumes 'test' as private key and 'local' as gdocId
    };
  }
  return window.bibcitationRequestData;
};

const PATH_PREFIX = '/citation-list/gdoc';
const gdocUrl = (gdocId: string): string =>
  createApiUrl(`${PATH_PREFIX}/${gdocId}`);
export const gdocHeadersFromToken = (
  token: string,
): Record<string, string> => ({
  'x-gdocs-token': token,
});

const upsertGdoc = async (state: DocumentStoreState): Promise<undefined> => {
  const { gdocId, token } = getRequestData();
  return axios.put(
    gdocUrl(gdocId),
    {
      citations: state.citations,
      styleKey: state.styleKey,
    },
    { headers: gdocHeadersFromToken(token) },
  );
};

const getGdoc = async (): Promise<DocumentStoreState | undefined> => {
  const { gdocId, token } = getRequestData();
  try {
    const res = await axios.get(gdocUrl(gdocId), {
      headers: gdocHeadersFromToken(token),
    });
    return {
      citations: res.data.citations,
      styleKey: res.data.styleKey,
    };
  } catch (error) {
    if (!runningInGdocs()) {
      return undefined;
    }
    if (axios.isAxiosError(error)) {
      if (error?.response?.status === 404) {
        return undefined;
      }
      throw error;
    }
    // Another error case? status code unknown -> not sure if it's 404 or not
    return getStateFromDocumentProperties();
  }
};

const defaultState: DocumentStoreState = {
  citations: [],
  styleKey: DEFAULT_STYLE_KEY,
};

export const getState = async (): Promise<DocumentStoreState> => {
  const state = await getGdoc();
  return {
    ...defaultState,
    ...state,
  };
};

export const setState = async (state: DocumentStoreState): Promise<void> => {
  return upsertGdoc(state);
};
