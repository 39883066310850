import React from 'react';

const Paper: React.FC<React.SVGProps<SVGSVGElement>> = (props): JSX.Element => (
  <svg height="16" viewBox="0 0 16 18" fill="none" {...props}>
    <path d="M4 9H11" stroke="currentColor" strokeLinecap="round" />
    <path d="M4 5H12" stroke="currentColor" strokeLinecap="round" />
    <path d="M4 11H12" stroke="currentColor" strokeLinecap="round" />
    <path d="M4 13H9" stroke="currentColor" strokeLinecap="round" />
    <path
      d="M15 1H1V17H15V1Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Paper;
