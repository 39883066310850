import CitationTypes from 'types/citation-types';
import CSLCitationTypes from 'types/csl-citation-types';
import reportException from './sentry';

const CSLToCitationTypeMap: { [key in CSLCitationTypes]: CitationTypes } = {
  [CSLCitationTypes['article']]: CitationTypes['journalArticle'], // no good mapping
  [CSLCitationTypes['article-journal']]: CitationTypes['journalArticle'],
  [CSLCitationTypes['article-magazine']]: CitationTypes['magazineArticle'],
  [CSLCitationTypes['article-newspaper']]: CitationTypes['newsArticle'],
  [CSLCitationTypes['bill']]: CitationTypes['legalBill'],
  [CSLCitationTypes['book']]: CitationTypes['book'],
  [CSLCitationTypes['broadcast']]: CitationTypes['tvRadioBroadcast'],
  [CSLCitationTypes['chapter']]: CitationTypes['bookChapter'],
  [CSLCitationTypes['dataset']]: CitationTypes['database'],
  [CSLCitationTypes['document']]: CitationTypes['document'],
  [CSLCitationTypes['entry']]: CitationTypes['encyclopediaEntry'], // no good mapping
  [CSLCitationTypes['entry-dictionary']]: CitationTypes['dictionaryEntry'],
  [CSLCitationTypes['entry-encyclopedia']]: CitationTypes['encyclopediaEntry'],
  [CSLCitationTypes['figure']]: CitationTypes['image'], // no good mapping
  [CSLCitationTypes['graphic']]: CitationTypes['artwork'],
  [CSLCitationTypes['interview']]: CitationTypes['interview'],
  [CSLCitationTypes['legal_case']]: CitationTypes['legalCase'],
  [CSLCitationTypes['legislation']]: CitationTypes['legislation'],
  [CSLCitationTypes['manuscript']]: CitationTypes['manuscript'],
  [CSLCitationTypes['map']]: CitationTypes['map'],
  [CSLCitationTypes['motion_picture']]: CitationTypes['filmOrMovie'],
  [CSLCitationTypes['musical_score']]: CitationTypes['song'], // no good mapping
  [CSLCitationTypes['pamphlet']]: CitationTypes['journalArticle'], // terrible mapping; ideally change
  [CSLCitationTypes['paper-conference']]: CitationTypes['conferencePaper'],
  [CSLCitationTypes['patent']]: CitationTypes['patent'],
  [CSLCitationTypes['personal_communication']]:
    CitationTypes['personalCommunication'],
  [CSLCitationTypes['post']]: CitationTypes['blogPost'], // no good mapping
  [CSLCitationTypes['post-weblog']]: CitationTypes['blogPost'],
  [CSLCitationTypes['report']]: CitationTypes['report'],
  [CSLCitationTypes['review']]: CitationTypes['review'],
  [CSLCitationTypes['review-book']]: CitationTypes['review'],
  [CSLCitationTypes['song']]: CitationTypes['song'],
  [CSLCitationTypes['speech']]: CitationTypes['speech'],
  [CSLCitationTypes['thesis']]: CitationTypes['thesis'],
  [CSLCitationTypes['treaty']]: CitationTypes['legalBill'], // no good mapping
  [CSLCitationTypes['video']]: CitationTypes['video'],
  [CSLCitationTypes['webpage']]: CitationTypes['website'],
};

const CSLToCitationType = (citationType: CSLCitationTypes): CitationTypes => {
  let cslType = CSLToCitationTypeMap[citationType];
  if (cslType === undefined) {
    reportException(
      `No conversion from CSLCitationTypes to CitationTypes exists for: ${citationType}`,
    );
    cslType = CitationTypes['journalArticle'];
  }
  return cslType;
};

export default CSLToCitationType;
