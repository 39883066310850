// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../types/window.d.ts" />

import axios from 'axios';
import type { Dict as MixpanelDict } from 'mixpanel-browser';
import yn from 'yn';
import createApiUrl from './create-api-url';

declare global {
  interface Window {
    initialized?: boolean;
    anonymousProperties?: UserProperties;
    setAnonymousProperties: (
      distinctId: string,
      properties: UserProperties,
    ) => void;
  }
}

export type EventProperties = MixpanelDict;
export type UserProperties = Record<string, string | number | boolean>;

if (
  (yn(process.env.CHROME) || yn(process.env.GDOCS)) &&
  typeof window !== 'undefined' &&
  process.env.MIXPANEL_API_KEY
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  window.mixpanel = require('mixpanel-browser').default;
  window.initialized = false;
  window.mixpanel.init(process.env.MIXPANEL_API_KEY, {
    persistence: 'localStorage',
  });
}

if (typeof window !== 'undefined' && process.env.MIXPANEL_API_KEY) {
  window.setAnonymousProperties = (
    distinctId: string,
    properties: UserProperties,
  ): void => {
    // somewhat hacky - there's no proper way to check for an anonymous user, but they are given the distinct id `$device:[UUID]`
    if (distinctId.startsWith('$')) {
      axios.post(createApiUrl('/external/mixpanel/user-properties'), [
        {
          $token: process.env.MIXPANEL_API_KEY,
          $distinct_id: distinctId,
          $set: properties,
        },
      ]);
    }
  };
}

const Tracking = {
  init(): void {
    if (window.mixpanel) {
      if (!process.env.MIXPANEL_API_KEY) {
        console.error('Failed initializing Mixpanel');
      }
      window.initialized = true;
      this._setInitProperties();
    }
  },
  _setInitProperties(): void {
    let client;
    if (yn(process.env.CHROME)) {
      client = 'chrome';
    } else if (yn(process.env.GDOCS)) {
      client = 'gdocs';
    } else {
      client = 'web';
    }
    this.setUserProperties({
      client: client,
    });
  },
  trackPage(page: string, properties: EventProperties = {}): void {
    if (typeof window === 'undefined') return;
    if (!window.initialized) this.init();
    if (window.mixpanel) {
      window.mixpanel.track_pageview({ page, ...properties });
    }
  },
  trackEvent(event: string, properties: EventProperties = {}): void {
    if (typeof window === 'undefined') return;
    if (!window.initialized) this.init();
    if (window.mixpanel) {
      window.mixpanel.track(event, properties);
    }
  },
  setUserProperties(properties: UserProperties): void {
    if (typeof window === 'undefined') return;
    if (!window.initialized) this.init();
    if (window.mixpanel) {
      window.mixpanel.people.set(properties);

      // if the user isn't logged in, the properties are only queued up to be sent upon login so we need to set them separately for anonymous users
      const distinctId: string | undefined =
        window.mixpanel.get_distinct_id?.(); // unlike other functions, get_distinct_id does not exist if mixpanel has not fully loaded
      if (distinctId !== undefined) {
        window.setAnonymousProperties(distinctId, properties);
      } else {
        window.anonymousProperties = {
          ...(window.anonymousProperties || {}),
          ...properties,
        };
      }
    }
  },
  setAbTestGroups(abTestGroups: UserProperties): void {
    this.setUserProperties(abTestGroups);
  },
  setUserId(userId: number): void {
    if (typeof window === 'undefined') return;
    if (!window.initialized) this.init();
    const userIdString = userId.toString();
    if (window.mixpanel) {
      window.mixpanel.identify(userIdString);
    }
  },
  handleLogout(): void {
    if (typeof window === 'undefined') return;
    if (!window.initialized) this.init();
    if (window.mixpanel) {
      window.mixpanel.reset();
    }
    this._setInitProperties();
  },
};

export default Tracking;
