import { Citation } from 'redux/modules/citation-module';
import { CitationStyle } from 'types/citation-style';
import { v4 as uuid } from 'uuid';
import { isOfficeSetSupported } from './is-office-set-supported';

export type DocumentId = string;

const documentIdKey = 'bibcitation-word-document-id';
const citationListKey = 'bibcitation-word-citation-list';

type Store = {
  get: (key: string) => Promise<unknown>;
  set: (key: string, value: unknown) => Promise<void>;
};

const settingsStore: Store = {
  async get(key) {
    const value = Office.context.document.settings.get(key);
    return value ?? undefined;
  },
  async set(key, value) {
    Office.context.document.settings.set(key, value);
    return new Promise((resolve) =>
      Office.context.document.settings.saveAsync(() => resolve()),
    );
  },
};

const localStore: Store = {
  async get(key) {
    const obj = localStorage.getItem(key);
    return obj !== null ? JSON.parse(obj)[key] : undefined;
  },
  async set(key, value) {
    return localStorage.setItem(key, JSON.stringify({ [key]: value }));
  },
};

const getStore = async (): Promise<Store> => {
  await Office.onReady();
  const settingsSupported = isOfficeSetSupported('Settings', '1.1');
  return settingsSupported ? settingsStore : localStore;
};

export const getDocumentId = async (): Promise<DocumentId> => {
  const store = await getStore();
  const existingId = await store.get(documentIdKey);
  if (existingId) return existingId as DocumentId;
  const documentId = uuid();
  await store.set(documentIdKey, documentId);
  return documentId;
};

type List = {
  id: string;
  style: CitationStyle;
  citations: Citation[];
};

export const getCitationList = async (): Promise<List | undefined> => {
  const store = await getStore();
  return store.get(citationListKey) as Promise<List | undefined>;
};

export const setCitationList = async (list: List): Promise<void> => {
  const store = await getStore();
  return store.set(citationListKey, list);
};
