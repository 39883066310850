import yn from 'yn';

const getHerokuUrl = () =>
  `https://${process.env.HEROKU_APP_NAME}.herokuapp.com`;

const createWebUrl = (url = '/'): string => {
  const webUrlFromAppScript =
    yn(process.env.GDOCS) &&
    typeof window !== 'undefined' &&
    window?.bibcitationWebUrl;
  return `${
    webUrlFromAppScript || process.env.WEB_URL || getHerokuUrl()
  }${url}`;
};

export default createWebUrl;
