import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';

import citationReducer, {
  CitationState,
  initialState as initialCitationState,
  setListFromAnotherTab,
} from 'redux/modules/citation-module';
import citationListReducer, {
  CitationListState,
  initialState as initialCitationListState,
} from './modules/citation-list-module';
import abTestReducer, {
  ABTestState,
  initialState as initialABTestState,
} from 'redux/modules/ab-test-module';
import authReducer, {
  AuthState,
  initialState as initialAuthState,
} from 'redux/modules/auth-module';
import { createReduxEnhancer } from '@sentry/react';
import { useDispatch } from 'react-redux';
import { createWrapper } from 'next-redux-wrapper';
import { setupAxios } from 'models/auth-model';
import { onListChange } from 'lib/inter-tab-communication';
import { runningInGdocs } from 'lib/gdocs';
import { getRequestData } from 'lib/gdocs/document-store';

const sentryReduxEnhancer = createReduxEnhancer({
  stateTransformer: (state: Store) => {
    return {
      ...state,
      citation: {
        ...state.citation,
        citationStyles: [],
        citationStyle: {
          name: state.citation.citationStyle?.name,
        },
      },
      auth: {
        ...state.auth,
        data: state.auth.data
          ? {
              ...state.auth.data,
              currentCitations: {
                ...state.auth.data.currentCitations,
                style: state.auth.data.currentCitations?.style
                  ? {
                      name: state.auth.data.currentCitations.style.name,
                    }
                  : undefined,
              },
            }
          : undefined,
      },
    };
  },
  actionTransformer: (action) => {
    const payload = action.payload;
    if (payload?.xml) {
      return {
        ...action,
        payload: {
          name: payload.name,
        },
      };
    }
    if (action.type === 'citation/getCitationStyles/fulfilled') {
      return {
        ...action,
        payload: {},
      };
    }
    if (payload?.citation?.citationStyle?.xml) {
      return {
        ...action,
        payload: {
          ...payload,
          citation: {
            ...payload.citation,
            citationStyle: {
              name: payload.citation.citationStyle.name,
            },
          },
        },
      };
    }
    if (payload?.currentCitations?.style?.xml) {
      return {
        ...action,
        payload: {
          ...payload,
          currentCitations: {
            ...payload.currentCitations,
            style: {
              name: payload.currentCitations.style.name,
            },
          },
        },
      };
    }
    if (payload?.auth?.data?.currentCitations?.style?.xml) {
      return {
        ...action,
        payload: {
          ...payload,
          auth: {
            ...payload.auth,
            data: {
              ...payload.auth.data,
              currentCitations: {
                ...payload.auth.data.currentCitations,
                style: {
                  name: payload.auth.data.currentCitations.style.name,
                },
              },
            },
          },
        },
      };
    }
    return action;
  },
});

function makeConfiguredStore() {
  const preloadedState: {
    citation: CitationState;
    citationList: CitationListState;
    experiments: ABTestState;
    auth: AuthState;
  } = {
    citation: initialCitationState,
    citationList: initialCitationListState,
    experiments: initialABTestState,
    auth: initialAuthState,
  };

  return configureStore({
    reducer: {
      citation: citationReducer,
      citationList: citationListReducer,
      experiments: abTestReducer,
      auth: authReducer,
    },
    preloadedState,
    enhancers: [sentryReduxEnhancer],
  });
}

function makeStore(): ReturnType<typeof makeConfiguredStore> {
  const store = makeConfiguredStore();

  if (typeof window !== 'undefined') {
    setupAxios(store.dispatch);
    onListChange((updatedList) => {
      if (runningInGdocs() && updatedList.gdocId) {
        const gdocId = getRequestData().gdocId;
        if (gdocId !== updatedList.gdocId) {
          return;
        }
      } else {
        const state = store.getState();
        const updatedListIndex = state.citationList.lists.findIndex(
          (list) => list.id === updatedList.id,
        );
        if (state.citationList.currentListIndex !== updatedListIndex) {
          return;
        }
      }

      store.dispatch(
        setListFromAnotherTab({
          citations: updatedList.citations,
          style: updatedList.style,
        }),
      );
    });
  }

  return store;
}

export type AppStore = ReturnType<typeof makeStore>;
export type Store = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const wrapper = createWrapper(makeStore);

/* Export a dispatch type. https://github.com/kirill-konshin/next-redux-wrapper/issues/207#issuecomment-710063652 */
export type AppThunkDispatch = ThunkDispatch<Store, void, AnyAction>;
