import { rem } from 'lib/rem';
import { mod } from 'lib/mod';

export const grid = {
  module: '8px',
  columns: 12,
};

export const breakpointInts = {
  smallest: 300,
  small: 767,
  medium: 1023,
  large: 1120,
};

export const breakpoints = {
  smallest: breakpointInts.smallest + 'px',
  small: breakpointInts.small + 'px',
  medium: breakpointInts.medium + 'px',
  large: breakpointInts.large + 'px',
};

export const colors = {
  white: '#fff',
  tint: {
    purple: {
      light: 'rgba(140, 78, 241, 0.1)',
      dark: 'rgba(140, 78, 241, 0.8)',
    },
    red: 'rgba(243, 99, 99, 0.1)',
    grey: {
      darker3: 'rgba(77, 90, 97, 0.7)',
    },
  },
  grey: {
    lightest: '#F7F6F9',
    lighter: '#F1F0F5',
    light: '#E3E1EA',
    medium: '#ACA4C1',
    dark: '#8277A2',
    darker: '#353046',
    darker2: '#2D273A',
    darker3: '#4d5a61',
    darkest: '#211D2B',
  },
  purple: {
    lighter: '#F3EDFE',
    light: '#B080FF',
    medium: '#8C4EF1',
    dark: '#7728F9',
  },
  red: {
    medium: '#F36363',
    light: '#FEF0F0',
  },
  orange: {
    medium: '#F66436',
    light: '#FEEFEB',
  },
  yellow: {
    medium: '#CA9D00',
    light: '#FAF5E5',
  },
  green: {
    medium: '#9EB801',
    light: '#F5F8E6',
  },
  teal: {
    medium: '#33B787',
    light: '#EBF8F3',
  },
  turquoise: {
    medium: '#25D3BE',
    light: '#E9FBF9',
  },
  blue: {
    medium: '#4EA3F1',
    light: '#EDF6FE',
    doc: '#4E55F1',
  },
  navy: {
    medium: '#4E55F1',
    light: '#EDEEFE',
  },
};

export const defaults = {
  animationTime: '.3s',
  headerSize: '78px',
  borderRadius: {
    veryTiny: mod(0.62),
    tiny: mod(0.75),
    small: mod(1),
    medium: mod(2),
    large: mod(3),
  },
  shadow: {
    mediumNoBottomShadow:
      '0px -7px 16px -1px rgba(66, 0, 174, 0.08), 0px -6px 8px -3px rgba(66, 0, 174, 0.08), 0px -6px 4px -2px rgba(66, 0, 174, 0.08);',
    medium:
      '0px 4px 16px rgba(66, 0, 174, 0.08), 0px 2px 8px rgba(66, 0, 174, 0.08), 0px 1px 4px rgba(66, 0, 174, 0.08);',
    large:
      '0px 32px 64px rgba(66, 0, 174, 0.08), 0px 8px 32px rgba(66, 0, 174, 0.08), 0px 4px 16px rgba(66, 0, 174, 0.08), 0px 2px 8px rgba(66, 0, 174, 0.08), 0px 1px 4px rgba(66, 0, 174, 0.08);',
    red: {
      medium:
        '0px 4px 16px rgba(243, 99, 99, 0.08), 0px 2px 8px rgba(243, 99, 99, 0.08), 0px 1px 4px rgba(243, 99, 99, 0.08);',
    },
  },
  focusOutline:
    '0 0 0 3px var(--background-default), 0 0 0 5px var(--brand-default)',
};

export const text = {
  family:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  label: {
    weight: 600,
    medium: {
      size: rem(16),
      lineHeight: rem(20),
    },
    small: {
      size: rem(13),
      lineHeight: rem(16),
    },
  },
  paragraph: {
    weight: 400,
    medium: {
      size: rem(14),
      lineHeight: rem(18),
    },
    small: {
      size: rem(12),
      lineHeight: rem(18),
    },
  },
};
