import { CitationStyle } from '../types/citation-style';
import popularStyles from '../types/popular-styles';
import defaultStyle from './default-style';
import { v4 as uuidv4 } from 'uuid';
import { ListMetadata } from '../types/citation-list';

export const DEFAULT_LIST_NAME = 'Untitled Bibliography';
export const DEFAULT_CITATION_STYLE: CitationStyle = {
  name: popularStyles[3],
  xml: defaultStyle,
};
export const DEFAULT_STYLE_KEY = popularStyles[3];

// Returns all CitationList metadata in an object (no citations).
// Pass undefined to get all default values.
export const extractMetadataFromList = (list: {
  id: string;
  name?: string | null;
  styleKey?: string | null;
  shareId: string | null;
  allowEditing: boolean;
  collaborators: string[];
  length: number;
  owner?: string;
}): ListMetadata => {
  return {
    id: list.id,
    name: list?.name || DEFAULT_LIST_NAME,
    styleKey: list?.styleKey || DEFAULT_CITATION_STYLE.name,
    shareId: list?.shareId,
    allowEditing: !!list?.allowEditing,
    collaborators: list?.collaborators || [],
    owner: list?.owner,
    length: list.length,
  };
};

export const makeCitationListId = (): string => uuidv4();
