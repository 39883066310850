import { createSelector } from 'reselect';
import { Store } from 'web/redux/store';
import { CitationListState } from 'redux/modules/citation-list-module';
import { ListMetadata } from 'types/citation-list';

const getLists = ({ citationList }: Store): CitationListState['lists'] =>
  citationList.lists;
const getCurrentIndex = ({
  citationList,
}: Store): CitationListState['currentListIndex'] =>
  citationList.currentListIndex;

export const getCurrentList = createSelector(
  [getLists, getCurrentIndex],
  (lists, index) => lists[index],
);

export const isAllowedToEdit = (
  list: ListMetadata,
  email?: string,
): boolean => {
  return typeof email === 'string' && list.collaborators.includes(email)
    ? list.allowEditing
    : true;
};
