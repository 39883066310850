import { Name, Date } from 'types/fields';

// Currently only works for fields of type Name[], string, or Date [our custom type; not native JS date]
const isCitationFieldValEmpty = (field: unknown): boolean => {
  if (!field) return true;

  if (Array.isArray(field)) {
    return !isAuthorPresent(field);
    // check for other array fields here, eg. number
  } else if (typeof field === 'object') {
    return !isDatePresent(field);
  }

  return false;
};

const isDatePresent = (field: Date): boolean => {
  if ('date-parts' in field && field['date-parts'] && field['date-parts'][0]) {
    return !!field['date-parts'][0][0]; // Check if the year is present
  }
  const optionalFields = ['season', 'circa', 'literal', 'raw'];
  return optionalFields.some((fieldKey) => fieldKey in field);
};

const isAuthorPresent = (field: Name[]): boolean => {
  for (const possibleAuthor of field) {
    if (
      possibleAuthor &&
      (possibleAuthor.family || possibleAuthor.given || possibleAuthor.literal)
    )
      return true;
  }
  return false;
};

export default isCitationFieldValEmpty;
