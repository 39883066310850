import React from 'react';

const Website: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
): JSX.Element => (
  <svg height="16" viewBox="0 0 18 14" fill="none" {...props}>
    <rect
      x={1}
      y={1}
      width={16}
      height={12}
      rx={1}
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M1 6.5H17" stroke="currentColor" strokeLinecap="round" />
    <circle cx={4} cy={4} r={1} fill="currentColor" />
    <circle cx={7} cy={4} r={1} fill="currentColor" />
    <circle cx={10} cy={4} r={1} fill="currentColor" />
  </svg>
);

export default Website;
