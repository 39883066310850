import yn from 'yn';

const getHerokuUrl = () =>
  `https://${process.env.HEROKU_APP_NAME?.replace(
    '-web-',
    '-scraper-',
  )}.herokuapp.com`;

const createScraperUrl = (url = '/'): string => {
  const scraperUrlFromAppScript =
    yn(process.env.GDOCS) && window?.bibcitationScraperUrl;
  return `${
    scraperUrlFromAppScript || process.env.SCRAPER_URL || getHerokuUrl()
  }${url}`;
};

export default createScraperUrl;
